import UsersList from "../pages/AdminPages/UserList";
import ForgetPassword from "../pages/Common/Authentication/ForgetPassword";
import Login from "../pages/Common/Authentication/Login";
import ContactsList from "../pages/Common/ContactsList";
import DataSampleList from "../pages/Common/DataSampleList";
import ShipmentArchiveList from "../pages/Common/ShipmentArchiveList";
import AddNewShipment from "../pages/Common/AddNewShipment";
import Dashboard from "../pages/UserPages/Dashboard/Dashboard";
import InvoiceArchive from "../pages/Common/InvoiceArchive";
import InvoicePreview from "../pages/Common/InvoicePreview";
import PullShipmentSamples from "../pages/Common/PullShipmentSamples";
import BrokersList from "../pages/BrokersList";
import Success from "../pages/Success";
import Failure from "../pages/Failure";
import ShipmentReport from "../pages/Common/Report/ShipmentReport";
import ShipmentPreview from "../pages/Common/Report/ShipmentPreview";
import CompanyPreview from "../pages/Common/Report/CompanyPreview";
import CompanyReport from "../pages/Common/Report/CompanyReport";
import LabResult from "../pages/LabResult/LabResult";
import PayNow from "../pages/PayNow";
import DemoInvoice from "../pages/DemoInvoice";
import ShipmentLogs from "../pages/Common/ShipmentLogs";

const userProtectedRoutes = [{ path: "/dashboard", component: Dashboard }];
const adminProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/users-list", component: UsersList },
];
const commonProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/company-list", component: ContactsList },
  { path: "/data-sample-list", component: DataSampleList },
  { path: "/shipments-archive-list", component: ShipmentArchiveList },
  { path: "/add-new-shipment", component: AddNewShipment },
  { path: "/invoice-archive", component: InvoiceArchive },
  { path: "/invoice-preview/:id", component: InvoicePreview },
  { path: "/pull-shipment-samples/:id", component: PullShipmentSamples },
  { path: "/brokers-list", component: BrokersList },
  { path: "/shipment-report", component: ShipmentReport },
  { path: "/shipment-preview/:id", component: ShipmentPreview },
  { path: "/company-report", component: CompanyReport },
  { path: "/company-preview/:id", component: CompanyPreview },
  { path: "/lab-test-result/:id", component: LabResult },
  { path: "/demoInvoice", component: DemoInvoice },
  { path: "/shipment-log/:id", component: ShipmentLogs },
];
const publicRoutes = [
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPassword },
  { path: "/success", component: Success },
  { path: "/failure", component: Failure },
  { path: "/pay-now", component: PayNow },
];

const unAuthRoutes = [
  { path: "/invoice-preview/:id", component: InvoicePreview },
];
export {
  userProtectedRoutes,
  adminProtectedRoutes,
  commonProtectedRoutes,
  publicRoutes,
  unAuthRoutes,
};
