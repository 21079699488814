import { AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  Row,
  Col,
  Card,
  Container,
  CardTitle,
  CardText,
  CardBody,
  Label,
  Input,
  Button,
} from "reactstrap";
import ApiUtils from "../../api/ApiUtils";
import { convertAmountToWords } from "../../helper/NumberToWords";
import { useNavigate, useParams } from "react-router-dom";
import { ToasterError, ToasterSuccess } from "../../helper/ToasterHelper";

const LabResult = () => {
  const [resultList, setResultList] = useState([]);
  const [updatedResult, setUpdatedResult] = useState([]);
  const searchParam = useParams();
  const navigate = useNavigate();

  const optionResult = [
    { label: "Approved", value: 1 },
    { label: "Not Approved", value: 2 },
  ];

  async function getList() {
    await ApiUtils.getShipmentById(`id=${searchParam?.id}`)
      .then((res) => {
        if (res.status === 200) {
          let updatedResult = [];
          res?.data?.data?.ship_container.forEach((val) => {
            val.data_sample.flatMap((sample) => {
              let sampleData = [];
              let reports = [];
              let reult = res?.data?.data?.ship_data_sample
                .filter((d) => d.data_sample_id === sample.id)?.[0]
                ?.data_sample?.[0]?.data_companent.slice(-1)[0];
              sampleData.push({ ...reult });

              val.lab_report.forEach((report) => {
                reports.push(report);
              });

              updatedResult.push({
                data_companent: sampleData,
                ship_data_sample_id: sample.id,
                lab_report: reports,
                attachment_files: [],
                container_number: val.number,
              });
            });
          });

          setUpdatedResult(updatedResult);
          setResultList(res?.data?.data);
        }
      })
      .catch((error) => {
        if (error && error.data && error.data.message) {
          ToasterError(error.data.message);
        } else if (error && error.data) {
          ToasterError(error.data);
        } else if (error) {
          ToasterError(error);
        }
      });
  }

  useEffect(() => {
    getList();
    if (!searchParam?.id) {
      navigate("/shipment-report");
    }
  }, []);

  const handleChange = (e, item, number) => {
    let updatedResult = { ...item };
    updatedResult[e?.target?.name || e?.name] = e?.target?.value || e?.value;
    setUpdatedResult((prevState) =>
      prevState.map((component) => {
        return {
          ...component,
          data_companent: component.data_companent.map((data) =>
            component.container_number === number && data.id === item.id
              ? updatedResult
              : data
          ),
        };
      })
    );
  };

  const handleFileSelect = (e) => {
    let updatedResultState = updatedResult?.map((item) => {
      if (Number(item.ship_data_sample_id) === Number(e.target.id)) {
        const filesArray = Array.from(e.target.files);
        const newFiles = filesArray.map((file) => {
          return file;
        });
        return {
          ...item,
          attachment_files: [...item.attachment_files, ...newFiles],
        };
      } else {
        return { ...item };
      }
    });
    setUpdatedResult(updatedResultState);
  };

  const handleComponentChange = (e, item) => {
    let data = [];
    for (let i = 0; i < e.value; i++) {
      data.push({
        id: "",
        data_sample_id: "",
        name: "",
        cas_code: "",
        percentage: "",
        test_type: "",
        test_method: "",
        test_unit: "",
        test_result: "",
        status: "",
      });
    }
    let updateResultState = [...updatedResult];

    updateResultState = updateResultState.map((result) =>
      result.container_number === item.number &&
      result.ship_data_sample_id === item.ship_data_sample_id
        ? { ...result, data_companent: data }
        : {
            ...result,
            data_companent: result.data_companent ? result.data_companent : [],
          }
    );
    setUpdatedResult(updateResultState);
  };

  // ***************************************
  // HANDLE LAB ANALYSIS SUBMISSION TO KEPA
  // ***************************************
  const handleSubmit = (result, data) => {

    const formData = new FormData();
    formData.append("ship_id", resultList.id);
    formData.append(`ship_container_id`, result.id);
    let count = 0;
    data.map((c) => {
      if (c.container_number === result.number) {
        if (c.attachment_files[0]) {
          formData.append(
            `ship_lab_result_file[${count}][ship_data_sample_id]`,
            c.ship_data_sample_id ? c.ship_data_sample_id : ""
          );
          formData.append(
            `ship_lab_result_file[${count}][ship_container_number]`,
            c.container_number ? c.container_number : ""
          );
          formData.append(
            `ship_lab_result_file[${count}][file]`,
            c.attachment_files[0]
          );
        }
        c.data_companent.map((d) => {
          formData.append(`data_component[${count}][id]`, d.id);
          formData.append(`data_component[${count}][test_type]`, d.test_type);
          formData.append(`data_component[${count}][test_method]`, d.test_method);
          formData.append(`data_component[${count}][test_unit]`, d.test_unit);
          formData.append(`data_component[${count}][test_result]`, d.test_result);
          formData.append(`data_component[${count}][status]`, d.status);
          count++;
        });
      }
    });
    ApiUtils.updateDataComponent(formData)
      .then((res) => {
        if (res.status === 200) {
          ToasterSuccess(res.data.message);
        }
      })
      .catch((error) => {
        if (error && error.data && error.data.message) {
          ToasterError(error.data.message);
        } else if (error && error.data) {
          ToasterError(error.data);
        } else if (error) {
          ToasterError(error);
        }
      });
  };

  const handleComponent = (
    selectedOption,
    data_companent,
    index,
    ship_data_sample_id,
    number_of_container
  ) => {
    let resultedData = data_companent.flatMap((i) =>
      i.data_companent.filter((c) => c.id === selectedOption.value)
    );
    let updatedResultState = updatedResult
      ?.filter(
        (r) =>
          r.ship_data_sample_id === ship_data_sample_id &&
          r.container_number === number_of_container
      )?.[0]
      ?.data_companent.map((data, i) =>
        i === index ? resultedData?.[0] : data
      );
    let dataForState = updatedResult.map((result) =>
      result.ship_data_sample_id === ship_data_sample_id &&
      result.container_number === number_of_container
        ? { ...result, data_companent: updatedResultState }
        : { ...result }
    );
    setUpdatedResult(dataForState);
  };

  const invoiceTotalAmountInWords = convertAmountToWords(
    resultList?.tota_amount
  );

  return (
    <React.Fragment>
      <div className="page-content" id="invoice-preview">
        <Container fluid={true}>
          <Row>
            <Col xs={12}>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0">Lab Test Result</h4>
              </div>
            </Col>
          </Row>
          <Card style={{ paddingTop: "20px" }}>
            <CardBody>
              <div className="row justify-content-center">
                <Row>
                  <Col lg={4}>
                    <Card body className="card-body-new-shipment">
                      <CardTitle className="h4 card-title-new-shipment">
                        Shipment Date
                      </CardTitle>
                      <CardText className="card-text-new-shipment">
                        {resultList.ship_date}
                      </CardText>
                    </Card>
                  </Col>
                  <Col lg={4}>
                    <Card body className="card-body-new-shipment">
                      <CardTitle className="h4 card-title-new-shipment">
                        Customs Declaration No.
                      </CardTitle>
                      <CardText className="card-text-new-shipment">
                        {resultList.customs_decleration_number}
                      </CardText>
                    </Card>
                  </Col>
                  <Col lg={4}>
                    <Card body className="card-body-new-shipment">
                      <CardTitle className="h4 card-title-new-shipment">
                        Shipment Status
                      </CardTitle>
                      <CardText className="card-text-new-shipment">
                        {resultList?.status === 1
                          ? "Waiting for shipment"
                          : resultList?.status === 2
                          ? "Approval for pull sample"
                          : resultList?.status === 3
                          ? "Final approval from KEPA "
                          : ""}
                      </CardText>
                    </Card>
                  </Col>{" "}
                </Row>
                <Row>
                  <Col lg={4}>
                    <Card body className="card-body-new-shipment">
                      <CardTitle className="h4 card-title-new-shipment">
                        Shipment Type
                      </CardTitle>
                      <CardText className="card-text-new-shipment">
                        {resultList?.ship_type === 1
                          ? "Import"
                          : resultList?.ship_type === 2
                          ? "Export"
                          : ""}
                      </CardText>
                    </Card>
                  </Col>
                  <Col lg={4}>
                    <Card body className="card-body-new-shipment">
                      <CardTitle className="h4 card-title-new-shipment">
                        Content Type
                      </CardTitle>
                      <CardText className="card-text-new-shipment">
                        {resultList?.container_type?.map((text, i) => {
                          return (
                            <span
                              key={i}
                              style={{ textTransform: "capitalize" }}
                            >
                              {text?.name}
                            </span>
                          );
                        })}
                      </CardText>
                    </Card>
                  </Col>
                  <Col lg={4}>
                    <Card body className="card-body-new-shipment">
                      <CardTitle className="h4 card-title-new-shipment">
                        AWB Number
                      </CardTitle>
                      <CardText className="card-text-new-shipment">
                        {resultList?.awb_number}
                      </CardText>
                    </Card>
                  </Col>{" "}
                </Row>
                <Row>
                  <Col lg={6}>
                    <Card body className="card-body-new-shipment">
                      <div className="d-flex align-items-center">
                        <div className="left-content">
                          <CardTitle className="h4 card-title-new-shipment strait-line">
                            {resultList?.ship_type === 1 ? "Import" : "Export"}
                            Company
                          </CardTitle>
                          <CardText className="card-text-new-shipment strait-line">
                            {resultList?.client_contact?.map((text, i) => {
                              return (
                                <React.Fragment key={i}>
                                  <span>{text?.company_name}</span>
                                </React.Fragment>
                              );
                            })}
                          </CardText>
                        </div>
                        <div className="vertical-line"></div>
                        <div className="right-content d-flex flex-column">
                          <CardTitle className="h4 card-title-new-shipment">
                            Contact Number
                          </CardTitle>
                          <CardText className="card-text-new-shipment">
                            {resultList?.client_contact?.map((text, i) => {
                              return (
                                <React.Fragment key={i}>
                                  <span>{text?.mobile_number}</span>
                                </React.Fragment>
                              );
                            })}
                          </CardText>
                        </div>
                      </div>
                    </Card>
                  </Col>
                  <Col lg={6}>
                    <Card body className="card-body-new-shipment">
                      <div className="d-flex align-items-center">
                        <div className="left-content">
                          <CardTitle className="h4 card-title-new-shipment strait-line">
                            Customs Broker
                          </CardTitle>
                          <CardText className="card-text-new-shipment strait-line">
                            {resultList?.broker?.map((text, i) => {
                              return (
                                <React.Fragment key={i}>
                                  <span>{text?.name}</span>
                                </React.Fragment>
                              );
                            })}
                          </CardText>
                        </div>
                        <div className="vertical-line"></div>
                        <div className="right-content d-flex flex-column">
                          <CardTitle className="h4 card-title-new-shipment">
                            Contact Number
                          </CardTitle>
                          <CardText className="card-text-new-shipment">
                            {resultList?.broker?.map((text, i) => {
                              return (
                                <React.Fragment key={i}>
                                  <span>{text?.mobile_number}</span>
                                </React.Fragment>
                              );
                            })}
                          </CardText>
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <Card body className="card-body-new-shipment">
                      <CardTitle className="h4 card-title-new-shipment">
                        Number of Containers
                      </CardTitle>
                      <CardText className="card-text-new-shipment">
                        {!resultList?.total_container
                          ? 0
                          : resultList?.total_container}
                      </CardText>
                    </Card>
                  </Col>
                  <Col lg={4}>
                    <Card body className="card-body-new-shipment">
                      <CardTitle className="h4 card-title-new-shipment">
                        Chemical Containers
                      </CardTitle>
                      <CardText className="card-text-new-shipment">
                        {!resultList?.chemical_container
                          ? 0
                          : resultList?.chemical_container}
                      </CardText>
                    </Card>
                  </Col>
                  <Col lg={4}>
                    <Card body className="card-body-new-shipment">
                      <CardTitle className="h4 card-title-new-shipment">
                        Physical Containers
                      </CardTitle>
                      <CardText className="card-text-new-shipment">
                        {!resultList?.physical_container
                          ? 0
                          : resultList?.physical_container}
                      </CardText>
                    </Card>
                  </Col>{" "}
                </Row>
                <Row>
                  <Col lg={4}>
                    <Card body className="card-body-new-shipment">
                      <CardTitle className="h4 card-title-new-shipment">
                        Port Name
                      </CardTitle>
                      <CardText className="card-text-new-shipment">
                        {resultList?.port?.map((text, i) => {
                          return <span key={i}>{text?.name}</span>;
                        })}
                      </CardText>
                    </Card>
                  </Col>
                  <Col lg={4}>
                    <Card body className="card-body-new-shipment">
                      <CardTitle className="h4 card-title-new-shipment">
                        {resultList?.ship_type === 1 ? "Import" : "Export"}{" "}
                        Country
                      </CardTitle>
                      <CardText className="card-text-new-shipment">
                        {resultList?.importing_exporting_country?.map(
                          (text, i) => {
                            return <span key={i}>{text.name}</span>;
                          }
                        )}
                      </CardText>
                    </Card>
                  </Col>
                  <Col lg={4}>
                    <Card body className="card-body-new-shipment">
                      <CardTitle className="h4 card-title-new-shipment">
                        Manufacturing Company Name
                      </CardTitle>
                      <CardText className="card-text-new-shipment">
                        {resultList?.manufacturing_company_name}
                      </CardText>
                    </Card>
                  </Col>{" "}
                </Row>
                <Row>
                  <Col lg={4}>
                    <Card body className="card-body-new-shipment">
                      <CardTitle className="h4 card-title-new-shipment">
                        Origin Country
                      </CardTitle>
                      <CardText className="card-text-new-shipment">
                        {resultList?.origin_country?.map((text, i) => {
                          return <span key={i}>{text?.name}</span>;
                        })}
                      </CardText>
                    </Card>
                  </Col>
                  <Col lg={4}>
                    <Card body className="card-body-new-shipment">
                      <CardTitle className="h4 card-title-new-shipment">
                        Origin Certificate No.
                      </CardTitle>
                      <CardText className="card-text-new-shipment">
                        {resultList?.origin_certificate_number}
                      </CardText>
                    </Card>
                  </Col>
                  <Col lg={4}>
                    <Card body className="card-body-new-shipment">
                      <CardTitle className="h4 card-title-new-shipment">
                        Shipment Invoice #.
                      </CardTitle>
                      <CardText className="card-text-new-shipment">
                        {resultList?.invoice_number}
                      </CardText>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <Card body className="card-body-new-shipment">
                      <CardTitle className="h4 card-title-new-shipment bg-primary text-white">
                        Invoice No.
                      </CardTitle>
                      <CardText className="card-text-new-shipment">
                        {resultList?.invoice?.map((text, i) => {
                          return <span key={i}>{text?.id}</span>;
                        })}
                      </CardText>
                    </Card>
                  </Col>
                  <Col lg={4}>
                    <Card body className="card-body-new-shipment">
                      <CardTitle className="h4 card-title-new-shipment bg-primary text-white">
                        Invoice Total
                      </CardTitle>
                      <CardText className="card-text-new-shipment">
                        {resultList?.invoice?.map((text, i) => {
                          return (
                            <span key={i}>
                              {parseInt(text?.total_amount)} KWD
                            </span>
                          );
                        })}
                      </CardText>
                    </Card>
                  </Col>
                  <Col lg={4}>
                    <Card body className="card-body-new-shipment">
                      <CardTitle className="h4 card-title-new-shipment bg-primary text-white">
                        Total Amount in Words
                      </CardTitle>
                      <CardText
                        className="card-text-new-shipment"
                        style={{ textTransform: "capitalize" }}
                      >
                        {invoiceTotalAmountInWords &&
                          `${invoiceTotalAmountInWords} Kuwaiti Dinar`}
                      </CardText>
                    </Card>
                  </Col>{" "}
                </Row>
              </div>

              {resultList?.ship_container?.map((data) => {
                return (
                  <div key={data.id}>
                    <Row>
                      <Col lg="12">
                        <AvForm>
                          <Row>
                            <Col md="12" className="my-3">
                              <div className="custom-card">
                                <div className="card-header">
                                  <div
                                    className="mb-4"
                                    style={{
                                      display: "flex",
                                      gap: "50px",
                                      alignItems: "center",
                                      justifyContent: "space-around",
                                    }}
                                  >
                                    <div>
                                      <Label
                                        className="form-label"
                                        htmlFor="upload-samples"
                                      >
                                        <CardTitle className="fw-bold">
                                          Container Number
                                        </CardTitle>
                                      </Label>
                                      <CardTitle className="fs-6">
                                        <div className="badge bg-info font-size-16">
                                          {data?.number}
                                        </div>
                                      </CardTitle>
                                    </div>
                                    <div>
                                      <Label
                                        className="form-label"
                                        htmlFor="upload-samples"
                                      >
                                        <CardTitle className="fw-bold">
                                          Data Sample
                                        </CardTitle>
                                      </Label>
                                      <CardTitle className="fs-6">
                                        <div className="badge bg-dark font-size-16">
                                          {data?.data_sample_name}
                                        </div>
                                      </CardTitle>
                                    </div>
                                    <div>
                                      <Label
                                        className="form-label"
                                        htmlFor="upload-samples"
                                      >
                                        <CardTitle className="fw-bold">
                                          HS Code
                                        </CardTitle>
                                      </Label>
                                      <CardTitle className="fs-6">
                                        {data?.hsa_code}
                                      </CardTitle>
                                    </div>
                                    <div>
                                      <Label
                                        className="form-label"
                                        htmlFor="upload-samples"
                                      >
                                        <CardTitle className="fw-bold">
                                          Select No. of component
                                        </CardTitle>
                                      </Label>

                                      <Select
                                        // value={selectedValues}
                                        onChange={(e) =>
                                          handleComponentChange(e, data)
                                        }
                                        name={`component-${data.id}`}
                                        options={data?.data_sample?.flatMap(
                                          (d) =>
                                            Array.from(
                                              {
                                                length: d.data_companent.length,
                                              },
                                              (_, i) => ({
                                                value: i + 1,
                                                label: (i + 1).toString(),
                                              })
                                            )
                                        )}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        // value={{ value: 1, label: "1" }}
                                        value={{
                                          value: updatedResult?.filter(
                                            (v) =>
                                              v.ship_data_sample_id ===
                                                data.ship_data_sample_id &&
                                              v.container_number === data.number
                                          )?.[0]?.data_companent?.length,
                                          label: updatedResult?.filter(
                                            (v) =>
                                              v.ship_data_sample_id ===
                                                data.ship_data_sample_id &&
                                              v.container_number === data.number
                                          )?.[0]?.data_companent?.length,
                                        }}
                                      />

                                    </div>
                                    <div className="justify-content-end">
                                      <Label
                                        className="form-label"
                                        htmlFor="upload-samples"
                                      >
                                        <CardTitle className="fw-bold">
                                          Select Attachment
                                        </CardTitle>
                                      </Label>
                                      <Input
                                        type="file"
                                        name={`upload-file-${data.ship_data_sample_id}`}
                                        multiple
                                        id={data.ship_data_sample_id}
                                        onChange={(e) => handleFileSelect(e)}
                                      />
                                    </div>

                                    {resultList.ship_data_sample
                                      .filter(
                                        (d) =>
                                          d.data_sample_id ===
                                          data.ship_data_sample_id
                                      )
                                      .map(
                                        (d, index) =>
                                          d.lab_result_file && (
                                            <div
                                              className="mt-4 ml-5"
                                              key={index}
                                            >
                                              <a
                                                href={d.lab_result_file}
                                                download
                                                target="_blank"
                                              >
                                                <i className="ri-download-2-fill font-size-40 download-btn-inner"></i>
                                              </a>
                                            </div>
                                          )
                                      )}
                                  </div>
                                </div>

                                <div className="card-body">
                                  <Row>
                                    <Col>
                                      <Label className="form-label">
                                        Component Name
                                      </Label>
                                    </Col>
                                    <Col>
                                      <Label className="form-label">CAS</Label>
                                    </Col>
                                    <Col>
                                      <Label className="form-label">
                                        Test Type
                                      </Label>
                                    </Col>
                                    <Col>
                                      <Label className="form-label">
                                        Test Method
                                      </Label>
                                    </Col>
                                    <Col>
                                      <Label className="form-label">
                                        Test Unit
                                      </Label>
                                    </Col>
                                    <Col>
                                      <Label className="form-label">
                                        Test Result
                                      </Label>
                                    </Col>
                                    <Col>
                                      <Label className="form-label">
                                        Status
                                      </Label>
                                    </Col>
                                  </Row>
                                  {updatedResult
                                    .filter(
                                      (result) =>
                                        result.ship_data_sample_id ===
                                          data.ship_data_sample_id &&
                                        result.container_number === data.number
                                    )?.[0]
                                    ?.data_companent?.map((result, index) => {
                                      return (
                                        <>
                                          <Row className="mb-3" key={index}>
                                            <Col>
                                              <Select
                                                onChange={(selectedOption) =>
                                                  handleComponent(
                                                    selectedOption,
                                                    data.data_sample,
                                                    index,
                                                    data.ship_data_sample_id,
                                                    data.number
                                                  )
                                                }
                                                name={`component-name-${Math.floor(
                                                  Math.random() * 1000
                                                )}`}
                                                style={{
                                                  textTransform: "capitalize",
                                                }}
                                                options={data.data_sample.flatMap(
                                                  (i) =>
                                                    i.data_companent.map(
                                                      (c) => ({
                                                        value: c.id,
                                                        label: c.name,
                                                      })
                                                    )
                                                )}
                                                value={{
                                                  label: result.name,
                                                  value: result.id,
                                                }}
                                              />
                                            </Col>
                                            <Col>
                                              <Input
                                                type="text"
                                                name={`cas-code-${Math.floor(
                                                  Math.random() * 1000
                                                )}`}
                                                style={{
                                                  textTransform: "capitalize",
                                                }}
                                                className="form-control"
                                                value={result?.cas_code}
                                                readOnly
                                              />
                                            </Col>

                                            <Col>
                                              <div>
                                                <Input
                                                  type="text"
                                                  name="test_type"
                                                  style={{
                                                    textTransform: "capitalize",
                                                  }}
                                                  value={
                                                    result.test_type
                                                      ? result.test_type
                                                      : ""
                                                  }
                                                  className="form-control"
                                                  onChange={(e) =>
                                                    handleChange(
                                                      e,
                                                      result,
                                                      data.number
                                                    )
                                                  }
                                                />
                                              </div>
                                            </Col>
                                            <Col>
                                              <Input
                                                type="text"
                                                name="test_method"
                                                style={{
                                                  textTransform: "capitalize",
                                                }}
                                                value={
                                                  result.test_method
                                                    ? result.test_method
                                                    : ""
                                                }
                                                className="form-control"
                                                onChange={(e) =>
                                                  handleChange(
                                                    e,
                                                    result,
                                                    data.number
                                                  )
                                                }
                                              />
                                            </Col>
                                            <Col>
                                              <Input
                                                type="text"
                                                name="test_unit"
                                                style={{
                                                  textTransform: "capitalize",
                                                }}
                                                value={
                                                  result.test_unit
                                                    ? result.test_unit
                                                    : ""
                                                }
                                                className="form-control"
                                                onChange={(e) =>
                                                  handleChange(
                                                    e,
                                                    result,
                                                    data.number
                                                  )
                                                }
                                              />
                                            </Col>
                                            <Col>
                                              <Input
                                                type="text"
                                                name="test_result"
                                                style={{
                                                  textTransform: "capitalize",
                                                }}
                                                value={
                                                  result.test_result
                                                    ? result.test_result
                                                    : ""
                                                }
                                                className="form-control"
                                                onChange={(e) =>
                                                  handleChange(
                                                    e,
                                                    result,
                                                    data.number
                                                  )
                                                }
                                              />
                                            </Col>
                                            <Col>
                                              <Select
                                                name="status"
                                                options={optionResult.map(
                                                  (or) => ({
                                                    ...or,
                                                    name: `status`,
                                                  })
                                                )}
                                                classNamePrefix="select2-selection"
                                                placeholder="Select Result"
                                                onChange={(e) =>
                                                  handleChange(
                                                    e,
                                                    result,
                                                    data.number
                                                  )
                                                }
                                                value={optionResult.filter(
                                                  (opt) =>
                                                    opt.value === result.status
                                                )}
                                              />
                                            </Col>
                                          </Row>
                                        </>
                                      );
                                    })}
                                  <div className="d-flex justify-content-center mt-4">
                                    <Button
                                      type="button"
                                      color="info"
                                      style={{ position: "inherit" }}
                                      onClick={() =>
                                        handleSubmit(data, updatedResult)
                                      }
                                    >
                                      SEND RESULTS TO KEPA
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </AvForm>
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default LabResult;
