import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import MiniWidgets from "./MiniWidgets";
import "../../Common/datatables.scss";
import { useSelector } from "react-redux";
import { USER_TYPE } from "../../../config/constants";
import ShipmentTable from "../../CommonComponents/ShipmentTable";
import PieChart from "../Charts/PieChart";
import ApiUtils from "../../../api/ApiUtils";
import separateValues from "../../../helper/separateValue";
import { useUserRole } from "../../../helper/UserProvider";
import { ToasterError } from "../../../helper/ToasterHelper";

function Dashboard() {
  const { userRole } = useUserRole();
  const [dashboardCount, setDashboadCount] = useState([]);
  const [shipmentData, setShipmentData] = useState([]);
  const [paymentData, setPaymentData] = useState([]);
  const isUserTypeAdmin = userRole.role_id === USER_TYPE.ADMIN ? true : false;

  const [breadcrumbItems] = useState([]);

  const fetchDashBoardCount = async () => {
    await ApiUtils.getShipmentDashboardCount()
      .then((data) => {
        setDashboadCount(data?.data?.data);
      })
      .catch((error) => {
        if (error && error.data && error.data.message) {
          ToasterError(error.data.message);
        } else if (error && error.data) {
          ToasterError(error.data);
        } else if (error) {
          ToasterError(error);
        }
      });
  };

  const fetchShipmentChartData = async () => {
    await ApiUtils.getShipmentChart()
      .then((data) => {
        setShipmentData(data?.data?.data);
      })
      .catch((error) => {
        if (error && error.data && error.data.message) {
          ToasterError(error.data.message);
        } else if (error && error.data) {
          ToasterError(error.data);
        } else if (error) {
          ToasterError(error);
        }
      });
  };
  const fetchPaymentChartData = async () => {
    await ApiUtils.getPaymentChart()
      .then((data) => {
        setPaymentData(data?.data?.data);
      })
      .catch((error) => {
        if (error && error.data && error.data.message) {
          ToasterError(error.data.message);
        } else if (error && error.data) {
          ToasterError(error.data);
        } else if (error) {
          ToasterError(error);
        }
      });
  };

  const { data: shipValue, label: shimentLabel } = separateValues(shipmentData);
  const { data: paymentValue, label: paymentLabel } =
    separateValues(paymentData);

  useEffect(() => {
    fetchDashBoardCount();
    fetchShipmentChartData();
    fetchPaymentChartData();
  }, []);

  const reports = [
    {
      icon: "ri-ship-line",
      title: "Number of Shipments",
      value: dashboardCount?.shipment_count,
    },
    {
      icon: "ri-stack-line",
      title: "Number of Containers",
      value: dashboardCount?.ship_container_count,
    },
    {
      icon: "ri-team-line",
      title: "Number of Users",
      value: dashboardCount?.user_count,
    },
    {
      icon: "ri-building-4-line",
      title: "Number of clients",
      value: dashboardCount?.client_contact_count,
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Dashboard" breadcrumbItems={breadcrumbItems} />
          <Row>
            <MiniWidgets reports={reports} />
          </Row>
          {isUserTypeAdmin && (
            <Row>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Shipment</CardTitle>
                    {shipValue?.length > 0 && (
                      <PieChart
                        data={shipValue}
                        label={shimentLabel}
                        colors={[
                          "#34c38f",
                          "#556ee6",
                          "#f46a6a",
                          "#50a5f1",
                          "#f1b44c",
                        ]}
                      />
                    )}
                  </CardBody>
                </Card>
              </Col>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Invoice</CardTitle>
                    {paymentValue?.length && (
                      <PieChart
                        data={paymentValue}
                        label={paymentLabel}
                        colors={["#34c38f", "#f1b44c"]}
                      />
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          <ShipmentTable updateDashboardCount={fetchDashBoardCount} />
        </Container>
      </div>
    </React.Fragment>
  );
}

export default Dashboard;
