import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, TabContent, NavItem, NavLink, Progress } from "reactstrap";

import classnames from "classnames";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Step1 from "./ShipmentSteps/Step1";
import Step2 from "./ShipmentSteps/Step2";
import Step3 from "./ShipmentSteps/Step3";
import Step4 from "./ShipmentSteps/Step4";
import ApiUtils from "../../api/ApiUtils";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentStepNumber, setShipmentId } from "../../redux/slices/shipmentSlice";
import { ToasterError } from "../../helper/ToasterHelper";

function AddNewShipment() {
  const [activeTabProgress, setActiveTabProgress] = useState(1);
  const [progressValue, setProgressValue] = useState(25);
  const [clickedNextTab, setClickedNextTab] = useState(false);
  const [allShipmentDetails, setAllShipmentDetails] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const shipmentData = useSelector((state) => state?.shipmentSlice);
  const dispatch = useDispatch();
  let locationData = useLocation();

  locationData = parseInt(locationData?.search.split("=")[1]);

  useEffect(() => {
    fetchShipmentList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabProgress, shipmentData]);

  useEffect(() => {
    if (locationData) {
      dispatch(setShipmentId(locationData));
    } else {
      // dispatch(setShipmentId(""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationData]);
  async function fetchShipmentList() {
    if (shipmentData?.id) {
      setLoading(true);
      await ApiUtils.getShipmentById(`id=${shipmentData?.id}`)
        .then((res) => {
          if (res.status === 200) {
            setAllShipmentDetails(res?.data?.data);
            setLoading(false);
          }
        })
        .catch((error) => {
          if (error && error.data && error.data.message) {
            ToasterError(error.data.message);
          } else if (error && error.data) {
            ToasterError(error.data);
          } else if (error) {
            ToasterError(error);
          }
          navigate("/dashboard");
          setLoading(false);
          setAllShipmentDetails("");
        });
    }
  }

  const toggleTabProgress = (type) => {
    if (type === "PREV") {
      setActiveTabProgress(activeTabProgress - 1);
      setProgressValue(progressValue - 25);
    } else {
      setClickedNextTab(true);
    }
  };

  const onSaveDraft = async () => {
    const formData = new FormData();
    formData.append("step", 4);
    formData.append("id", shipmentData?.id);
    await ApiUtils.createShipment(formData)
      .then((res) => {
        if (res.status === 200) {
          dispatch(setShipmentId(""));
          dispatch(setCurrentStepNumber(4));
          navigate("/shipments-archive-list");
        }
      })
      .catch((error) => {
        if (error && error.data && error.data.message) {
          ToasterError(error.data.message);
        } else if (error && error.data) {
          ToasterError(error.data);
        } else if (error) {
          ToasterError(error);
        }
      });
  };
  const onConfirmInvoice = async () => {
    const formData = new FormData();
    formData.append("step", 5);
    formData.append("id", shipmentData?.id);
    await ApiUtils.createShipment(formData)
      .then((res) => {
        if (res.status === 200) {
          dispatch(setShipmentId(""));
          dispatch(setCurrentStepNumber(5));
          // dispatch(setShipmentDetails(res?.data?.data?.shipment));
          navigate(`/pull-shipment-samples/${shipmentData?.id}`, {
            state: {
              shipment_id: shipmentData?.id,
            },
          });
        }
      })
      .catch((error) => {
        if (error && error.data && error.data.message) {
          ToasterError(error.data.message);
        } else if (error && error.data) {
          ToasterError(error.data);
        } else if (error) {
          ToasterError(error);
        }
      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col xs={12}>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 my-4">Add New Shipment</h4>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div id="progrss-wizard" className="twitter-bs-wizard">
                    <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTabProgress === 1,
                          })}
                        >
                          <span className="step-number">01</span>
                          <span className="step-title">Type</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTabProgress === 2,
                          })}
                        >
                          <span className="step-number">02</span>
                          <span className="step-title">Details</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTabProgress === 3,
                          })}
                        >
                          <span className="step-number">03</span>
                          <span className="step-title">Contents</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTabProgress === 4,
                          })}
                        >
                          <span className="step-number">04</span>
                          <span className="step-title">Confirm </span>
                        </NavLink>
                      </NavItem>
                    </ul>

                    <div id="bar" className="mt-4">
                      <Progress
                        color="success"
                        striped
                        animated
                        value={progressValue}
                      />
                    </div>
                    <TabContent
                      activeTab={activeTabProgress}
                      className="twitter-bs-wizard-tab-content"
                    >
                      {activeTabProgress === 1 ? (
                        <Step1
                          tabId={1}
                          clickedNextTab={
                            activeTabProgress === 1 && clickedNextTab
                          }
                          setActiveTabProgress={setActiveTabProgress}
                          setClickedNextTab={setClickedNextTab}
                          setProgressValue={setProgressValue}
                          progressValue={progressValue}
                          allShipmentDetails={allShipmentDetails}
                          loading={loading}
                          searchShipmentId={locationData}
                        />
                      ) : activeTabProgress === 2 ? (
                        <Step2
                          tabId={2}
                          clickedNextTab={
                            activeTabProgress === 2 && clickedNextTab
                          }
                          setActiveTabProgress={setActiveTabProgress}
                          setClickedNextTab={setClickedNextTab}
                          setProgressValue={setProgressValue}
                          progressValue={progressValue}
                          allShipmentDetails={allShipmentDetails}
                          searchShipmentId={locationData}
                        />
                      ) : activeTabProgress === 3 ? (
                        <Step3
                          tabId={3}
                          clickedNextTab={
                            activeTabProgress === 3 && clickedNextTab
                          }
                          setActiveTabProgress={setActiveTabProgress}
                          setClickedNextTab={setClickedNextTab}
                          setProgressValue={setProgressValue}
                          progressValue={progressValue}
                          allShipmentDetails={allShipmentDetails}
                          searchShipmentId={locationData}
                        />
                      ) : (
                        <Step4
                          tabId={4}
                          allShipmentDetails={allShipmentDetails}
                        />
                      )}
                    </TabContent>
                    <ul className="pager wizard twitter-bs-wizard-pager-link">
                      <li
                        className={
                          activeTabProgress === 1
                            ? "previous disabled"
                            : "previous"
                        }
                      >
                        <Link
                          to="#"
                          onClick={() => {
                            toggleTabProgress("PREV");
                          }}
                          style={{
                            display: activeTabProgress === 1 ? "none" : "block",
                          }}
                        >
                          Previous
                        </Link>
                      </li>
                      {activeTabProgress === 4 && (
                        <>
                          <li>
                            <button
                              // type="submit"
                              className="btn btn-info"
                              onClick={
                                allShipmentDetails.invoice.length === 0
                                  ? onSaveDraft
                                  : onConfirmInvoice
                              }
                            >
                              {allShipmentDetails.invoice.length === 0
                                ? "SAVE DRAFT"
                                : "SAVE"}
                            </button>
                          </li>
                          {allShipmentDetails.invoice.length === 0 && (
                            <li
                              className={
                                activeTabProgress === 4
                                  ? "next disabled"
                                  : "next"
                              }
                            >
                              <button
                                type="submit"
                                form="step-2"
                                className="btn btn-info"
                                onClick={onConfirmInvoice}
                              >
                                CONFIRM & SEND INVOICE
                              </button>
                            </li>
                          )}
                        </>
                      )}
                      {activeTabProgress < 4 && (
                        <li
                          className={
                            activeTabProgress === 4 ? "next disabled" : "next"
                          }
                        >
                          <button
                            type="submit"
                            // form={activeTabProgress === 2 && "step-2"}
                            form={
                              activeTabProgress === 2
                                ? activeTabProgress === 2 && "step-2"
                                : activeTabProgress === 3
                                ? activeTabProgress === 3 && "step-3"
                                : ""
                            }
                            className="btn btn-info"
                            onClick={() => {
                              toggleTabProgress("NEXT");
                            }}
                          >
                            Next
                          </button>
                        </li>
                      )}
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AddNewShipment;
