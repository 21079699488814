import React, { useState } from "react";

import Navbar from "./Navbar";
import Header from "./Header";
import Footer from "./Footer";

const Layout = (props) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const toggleRightSidebar = () => {
    // Function logic goes here
  };

  const openMenu = () => {
    setIsMenuOpened(!isMenuOpened);
  };

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header
          // theme={topbarTheme}
          isMenuOpened={isMenuOpened}
          toggleRightSidebar={toggleRightSidebar}
          openLeftMenuCallBack={openMenu}
        />
        <Navbar menuOpen={isMenuOpened} openLeftMenuCallBack={openMenu} />
        <div className="main-content">
          {props.children}
          <Footer />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Layout;
