import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
// users
import { useDispatch, useSelector } from "react-redux";
import { removeUserAuthDetails } from "../../../redux/slices/authSlice";
import { USER_TYPE } from "../../../config/constants";
import ApiUtils from "../../../api/ApiUtils";
import { ToasterError } from "../../../helper/ToasterHelper";
import { useUserRole } from "../../../helper/UserProvider";

const ProfileMenu = () => {
  const [menu, setMenu] = useState(false);
  const { userRole, clearUserData } = useUserRole();
  const toggle = () => {
    setMenu((prevState) => !prevState);
  };
  const dispatch = useDispatch();

  const handleLogout = () => {
    if (userRole.role_id === USER_TYPE.ADMIN) {
      ApiUtils.adminLogout()
        .then((res) => {
          if (res.status === 200) {
            dispatch(removeUserAuthDetails());
            localStorage.removeItem("mel-user");
            clearUserData();
          }
        })
        .catch((error) => {
          if (error && error.data && error.data.message) {
            ToasterError(error.data.message);
          } else if (error && error.data) {
            ToasterError(error.data);
          } else if (error) {
            ToasterError(error);
          }
          dispatch(removeUserAuthDetails());
          localStorage.removeItem("mel-user");
          clearUserData();
        });
    } else {
      ApiUtils.userLogout()
        .then((res) => {
          if (res.status === 200) {
            dispatch(removeUserAuthDetails());
            localStorage.removeItem("mel-user");
            clearUserData();
          }
        })
        .catch((error) => {
          if (error && error.data && error.data.message) {
            ToasterError(error.data.message);
          } else if (error && error.data) {
            ToasterError(error.data);
          } else if (error) {
            ToasterError(error);
          }
        });
    }
  };
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={toggle}
        className="d-inline-block user-dropdown text-dark"
      >
        <DropdownToggle
          tag="button"
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
        >
          <img
            className="rounded-circle header-profile-user me-1"
            src="/avatar.png"
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-1 text-transform text-dark">
            {userRole.name}
          </span>
          <i className="mdi mdi-chevron-down d-none ms-1 d-xl-inline-block text-dark"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem>
            <i className="ri-user-line align-middle me-1"></i> {"Profile"}
          </DropdownItem>

          <DropdownItem divider />
          <DropdownItem className="text-danger" onClick={handleLogout}>
            <i className="ri-shut-down-line align-middle me-1 text-danger"></i>{" "}
            {"Logout"}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileMenu;
