import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardTitle,
  CardText,
  CardBody,
  Table,
  Label,
  Button,
  Input,
} from "reactstrap";
import Select from "react-select";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ApiUtils from "../../api/ApiUtils";
import {
  ToasterError,
  ToasterSuccess,
  ToasterWarning,
} from "../../helper/ToasterHelper";
import { convertAmountToWords } from "../../helper/NumberToWords";

function PullShipmentSamples() {
  const [shipmentDetails, setAllShipmentDetails] = useState("");
  const shipmentData = useSelector((state) => state?.shipmentSlice);
  const [sampleData, setSampleData] = useState([]);
  const navigate = useNavigate();
  const searchParam = useParams();
  const { state } = useLocation();
  useEffect(() => {
    fetchShipmentList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  async function fetchShipmentList() {
    if (searchParam?.id) {
      await ApiUtils.getShipmentById(`id=${searchParam?.id}`)
        .then((res) => {
          if (res.status === 200) {
            let data = res?.data?.data?.ship_container.map((val) => ({
              ...val,
              ship_data_sample_id: val?.ship_data_sample_id
                ? val?.ship_data_sample_id
                : "",
              sample_barcode: val?.sample_barcode ? val?.sample_barcode : "",
              test_result: val?.test_result ? val?.test_result : "",
              epa_status: val?.epa_status ? val?.epa_status : "",
              files: [],
              container_type_id: res?.data?.data?.container_type[0]?.id,
            }));

            setAllShipmentDetails({ ...res?.data?.data, ship_container: data });
          }
        })
        .catch((err) => setAllShipmentDetails(""));
    }
  }

  useEffect(() => {
    // if (shipmentData?.stepNumber !== 5) {
    //   navigate("/add-new-shipment");
    // }
    let sampleData = [];
    if (shipmentDetails?.ship_data_sample?.length > 0) {
      shipmentDetails?.ship_data_sample?.forEach((val) =>
        val?.data_sample?.forEach((c) =>
          sampleData.push({
            value: c.id,
            label: c.name,
          })
        )
      );
      setSampleData(sampleData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipmentDetails]);

  const optionResult = [
    { label: "Approved", value: 1 },
    { label: "Not Approved", value: 2 },
  ];
  const handleSendtoEpa = async (data) => {
    if (data.ship_data_sample_id === "" || data.sample_barcode === "") {
      ToasterWarning("Please fill all the fields");
    } else {
      const formData = new FormData();
      let filesArray;
      filesArray = data?.ship_container_files?.map((f) => f.fileObject);
      for (const fileObject of filesArray) {
        formData.append("files[]", fileObject);
      }
      formData.getAll("files");
      formData.append("ship_data_sample_id", data.ship_data_sample_id);
      formData.append("sample_barcode", data.sample_barcode);
      formData.append("test_result", data.test_result);
      formData.append("epa_status", 2);
      formData.append("container_type_id", data.container_type_id);
      formData.append("id", data.id);
      await ApiUtils.updateShipContainer(formData)
        .then((res) => {
          if (res.status === 200) {
            ToasterSuccess(res?.data?.message);
            let data = shipmentDetails?.ship_container.map((val) =>
              val.id == res.data.data.id
                ? {
                    ...val,
                    epa_status: res.data.data.epa_status,
                  }
                : {
                    ...val,
                    epa_status: val.epa_status ? val.epa_status : "",
                  }
            );

            setAllShipmentDetails({ ...shipmentDetails, ship_container: data });
          }
        })
        .catch((error) => {
          if (error && error.data && error.data.message) {
            ToasterError(error.data.message);
          } else if (error && error.data) {
            ToasterError(error.data);
          } else if (error) {
            ToasterError(error);
          }
        });
    }
  };

  const handleChangeInput = (e, id) => {
    let shipContainer = shipmentDetails?.ship_container?.map((item) => {
      if (item.id == id) {
        return { ...item, sample_barcode: e.target.value };
      } else {
        return { ...item };
      }
    });
    setAllShipmentDetails({
      ...shipmentDetails,
      ship_container: shipContainer,
    });
  };
  const handleChangeDataSample = (e, id) => {
    let shipContainer = shipmentDetails?.ship_container?.map((item) => {
      if (item.id === id) {
        return { ...item, ship_data_sample_id: e.value };
      } else {
        return { ...item };
      }
    });
    setAllShipmentDetails({
      ...shipmentDetails,
      ship_container: shipContainer,
    });
  };
  // const handleChangeTestResult = (e, id) => {
  //   let shipContainer = shipmentDetails?.ship_container?.map((item) => {
  //     if (item.id === id) {
  //       return { ...item, test_result: e.value };
  //     } else {
  //       return { ...item };
  //     }
  //   });
  //   setAllShipmentDetails({
  //     ...shipmentDetails,
  //     ship_container: shipContainer,
  //   });
  // };

  const handleFileSelect = (e) => {
    let shipContainer = shipmentDetails?.ship_container?.map((item) => {
      if (Number(item.id) === Number(e.target.id)) {
        const filesArray = Array.from(e.target.files);
        const newFiles = filesArray.map((file) => {
          return {
            fileObject: file,
            file: URL.createObjectURL(file),
          };
        });
        return { ...item, ship_container_files: [...item.files, ...newFiles] };
      } else {
        return { ...item };
      }
    });
    setAllShipmentDetails({
      ...shipmentDetails,
      ship_container: shipContainer,
    });
  };
  const invoiceTotalAmountInWords = convertAmountToWords(
    shipmentDetails?.tota_amount
  );
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col xs={12}>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 my-4">Pull Shipment Samples</h4>
              </div>
            </Col>
          </Row>
          <Card style={{ padding: "24px" }}>
            <div className="row justify-content-center mt-5">
              <Row>
                <Col lg={4}>
                  <Card body className="card-body-new-shipment">
                    <CardTitle className="h4 card-title-new-shipment">
                      Shipment Date
                    </CardTitle>
                    <CardText className="card-text-new-shipment">
                      {shipmentDetails?.ship_date}
                    </CardText>
                  </Card>
                </Col>

                <Col lg={4}>
                  <Card body className="card-body-new-shipment">
                    <CardTitle className="h4 card-title-new-shipment">
                      Customs Declaration No.
                    </CardTitle>
                    <CardText className="card-text-new-shipment">
                      {shipmentDetails?.customs_decleration_number}
                    </CardText>
                  </Card>
                </Col>
                <Col lg={4}>
                  <Card body className="card-body-new-shipment">
                    <CardTitle className="h4 card-title-new-shipment">
                      Shipment Status
                    </CardTitle>
                    <CardText className="card-text-new-shipment">
                      {shipmentDetails?.status}
                    </CardText>
                  </Card>
                </Col>
                <Col lg={4}>
                  <Card body className="card-body-new-shipment">
                    <CardTitle className="h4 card-title-new-shipment">
                      Shipment Type
                    </CardTitle>
                    <CardText className="card-text-new-shipment">
                      {shipmentDetails?.ship_type === 1
                        ? "Import"
                        : shipmentDetails?.ship_type === 2
                        ? "Export"
                        : ""}
                    </CardText>
                  </Card>
                </Col>

                <Col lg={4}>
                  <Card body className="card-body-new-shipment">
                    <CardTitle className="h4 card-title-new-shipment">
                      Content Type
                    </CardTitle>
                    <CardText className="card-text-new-shipment">
                      {shipmentDetails?.container_type?.map((text, i) => {
                        return (
                          <span key={i} style={{ textTransform: "capitalize" }}>
                            {text?.name}
                          </span>
                        );
                      })}
                    </CardText>
                  </Card>
                </Col>
                <Col lg={4}>
                  <Card body className="card-body-new-shipment">
                    <CardTitle className="h4 card-title-new-shipment">
                      AWB Number
                    </CardTitle>
                    <CardText className="card-text-new-shipment">
                      {shipmentDetails?.awb_number}
                    </CardText>
                  </Card>
                </Col>
                <Col lg={6}>
                  <Card body className="card-body-new-shipment">
                    <CardTitle className="h4 card-title-new-shipment">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>
                          {shipmentDetails?.ship_type === 1
                            ? "Import"
                            : "Export"}{" "}
                          Company
                        </span>
                        <span>Contact Number</span>
                      </div>
                    </CardTitle>
                    <CardText className="card-text-new-shipment">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        {shipmentDetails?.client_contact?.map((text, i) => {
                          return (
                            <React.Fragment key={i}>
                              <span>{text?.company_name}</span>
                              <span>{text?.mobile_number}</span>
                            </React.Fragment>
                          );
                        })}
                      </div>
                    </CardText>
                  </Card>
                </Col>

                <Col lg={6}>
                  <Card body className="card-body-new-shipment">
                    <CardTitle className="h4 card-title-new-shipment">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>Customs Broker</span>
                        <span>Contact Number</span>
                      </div>
                    </CardTitle>
                    <CardText className="card-text-new-shipment">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        {shipmentDetails?.broker?.map((text, i) => {
                          return (
                            <React.Fragment key={i}>
                              <span>{text?.name}</span>
                              <span>{text?.mobile_number}</span>
                            </React.Fragment>
                          );
                        })}
                      </div>
                    </CardText>
                  </Card>
                </Col>

                <Col lg={4}>
                  <Card body className="card-body-new-shipment">
                    <CardTitle className="h4 card-title-new-shipment">
                      Number of Containers
                    </CardTitle>
                    <CardText className="card-text-new-shipment">
                      {!shipmentDetails?.total_container
                        ? 0
                        : shipmentDetails?.total_container}
                    </CardText>
                  </Card>
                </Col>
                <Col lg={4}>
                  <Card body className="card-body-new-shipment">
                    <CardTitle className="h4 card-title-new-shipment">
                      Chemical Containers
                    </CardTitle>
                    <CardText className="card-text-new-shipment">
                      {!shipmentDetails?.chemical_container
                        ? 0
                        : shipmentDetails?.chemical_container}
                    </CardText>
                  </Card>
                </Col>
                <Col lg={4}>
                  <Card body className="card-body-new-shipment">
                    <CardTitle className="h4 card-title-new-shipment">
                      Physical Containers
                    </CardTitle>
                    <CardText className="card-text-new-shipment">
                      {!shipmentDetails?.physical_container
                        ? 0
                        : shipmentDetails?.physical_container}
                    </CardText>
                  </Card>
                </Col>
                <Col lg={4}>
                  <Card body className="card-body-new-shipment">
                    <CardTitle className="h4 card-title-new-shipment">
                      Port Name
                    </CardTitle>
                    <CardText className="card-text-new-shipment">
                      {shipmentDetails?.port?.map((text, i) => {
                        return <span key={i}>{text?.name}</span>;
                      })}
                    </CardText>
                  </Card>
                </Col>

                <Col lg={4}>
                  <Card body className="card-body-new-shipment">
                    <CardTitle className="h4 card-title-new-shipment">
                      {shipmentDetails?.ship_type === 1 ? "Import" : "Export"}{" "}
                      Country
                    </CardTitle>
                    <CardText className="card-text-new-shipment">
                      {shipmentDetails?.importing_exporting_country?.map(
                        (text, i) => {
                          return <span key={i}>{text.name}</span>;
                        }
                      )}
                    </CardText>
                  </Card>
                </Col>
                <Col lg={4}>
                  <Card body className="card-body-new-shipment">
                    <CardTitle className="h4 card-title-new-shipment">
                      Manufacturing Company Name
                    </CardTitle>
                    <CardText className="card-text-new-shipment">
                      {shipmentDetails?.manufacturing_company_name}
                    </CardText>
                  </Card>
                </Col>
                <Col lg={4}>
                  <Card body className="card-body-new-shipment">
                    <CardTitle className="h4 card-title-new-shipment">
                      Origin Country{" "}
                    </CardTitle>
                    <CardText className="card-text-new-shipment">
                      {shipmentDetails?.origin_country?.map((text, i) => {
                        return <span key={i}>{text?.name}</span>;
                      })}
                    </CardText>
                  </Card>
                </Col>
                <Col lg={4}>
                  <Card body className="card-body-new-shipment">
                    <CardTitle className="h4 card-title-new-shipment">
                      Origin Certificate No.
                    </CardTitle>
                    <CardText className="card-text-new-shipment">
                      {shipmentDetails?.origin_certificate_number}
                    </CardText>
                  </Card>
                </Col>
                <Col lg={4}>
                    <Card body className="card-body-new-shipment">
                      <CardTitle className="h4 card-title-new-shipment">
                        Shipment Invoice #
                      </CardTitle>
                        <CardText className="card-text-new-shipment">
                          {shipmentDetails.invoice_number}
                        </CardText>
                    </Card>
                </Col>
                <Col lg={4}>
                  <Card body className="card-body-new-shipment">
                    <CardTitle className="h4 card-title-new-shipment bg-primary text-white">
                      Invoice No.
                    </CardTitle>
                    <CardText className="card-text-new-shipment">
                      {shipmentDetails?.invoice?.map((text, i) => {
                        return <span key={i}>{text?.id}</span>;
                      })}
                    </CardText>
                  </Card>
                </Col>
                <Col lg={4}>
                  <Card body className="card-body-new-shipment">
                    <CardTitle className="h4 card-title-new-shipment bg-primary text-white">
                      Invoice Total
                    </CardTitle>
                    <CardText className="card-text-new-shipment">
                      {shipmentDetails?.invoice?.map((text, i) => {
                        return (
                          <span key={i}>
                            {parseInt(text?.total_amount)} KWD
                          </span>
                        );
                      })}
                    </CardText>
                  </Card>
                </Col>
                <Col lg={4}>
                  <Card body className="card-body-new-shipment">
                    <CardTitle className="h4 card-title-new-shipment bg-primary text-white">
                      Total Amount in Words
                    </CardTitle>
                    <CardText
                      className="card-text-new-shipment"
                      style={{ textTransform: "capitalize" }}
                    >
                      {invoiceTotalAmountInWords &&
                        `${invoiceTotalAmountInWords} Kuwaiti Dinar`}
                    </CardText>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <>
                    <div className="table-rep-plugin">
                      <div
                        className="table-responsive mb-0"
                        data-pattern="priority-columns"
                      >
                        <Table
                          id="tech-companies-1"
                          striped
                          bordered
                          responsive
                          className="confirm-page-container-list"
                        >
                          <thead>
                            <tr>
                              <th data-priority="1">Data Sample</th>
                              <th data-priority="2">HS Code</th>
                              <th data-priority="3">Weight</th>
                            </tr>
                          </thead>
                          <tbody>
                            {shipmentDetails?.ship_data_sample?.map(
                              (val, i) => {
                                return (
                                  <tr key={i}>
                                    {val?.data_sample?.map((text, j) => {
                                      return (
                                        <React.Fragment key={j}>
                                          <td>{text?.name}</td>
                                          <td>{text?.hsa_code}</td>
                                        </React.Fragment>
                                      );
                                    })}
                                    <td>{val.weight}</td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </>
                </Col>
                <Col lg="12">
                  <div>
                    {shipmentDetails?.ship_container?.length > 0 &&
                      shipmentDetails?.ship_container?.map((data) => {
                        console.log(data);
                        return (
                          <AvForm key={data?.id}>
                            <Row>
                              <Col md="12" className="my-3">
                                <div className="custom-card">
                                  <div className="card-header d-flex justify-content-between">
                                    <Label
                                      className="form-label"
                                      htmlFor="upload-samples"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      Container <span
                                        className={"badge bg-dark"}
                                        style={{
                                          padding: "6px 12px",
                                          fontSize: "14px",
                                          marginLeft: "10px",
                                        }}
                                    >
                                      #{data.number}</span>
                                      <div
                                        className={`badge bg-${
                                          data?.epa_status === 2
                                            ? "success"
                                            : "warning"
                                        }`}
                                        style={{
                                          padding: "6px 12px",
                                          fontSize: "14px",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        <span>
                                          {data?.epa_status === 2
                                            ? "Sent to KEPA"
                                            : "Pending"}
                                        </span>
                                      </div>
                                    </Label>

                                    <span>
                                      <Button
                                        type="button"
                                        color="info"
                                        name={`send-button-${data.id}`}
                                        className="waves-effect waves-light mx-3 btn-info"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          gap: "8px",
                                          borderRadius: "25px",
                                          padding: "6px 12px",
                                          fontSize: "14px",
                                        }}
                                        onClick={() => handleSendtoEpa(data)}
                                        // disabled={ data.epa_status === 2 }
                                      >
                                        <i className=" ri-send-plane-fill font-size-18" />
                                        <span>Send to EPA</span>
                                      </Button>
                                    </span>
                                  </div>
                                  <div className="card-body">
                                    <Row>
                                      <Col lg="8">
                                        <Row>
                                          <Col lg={6}>
                                            <div className="mb-3">
                                              <Label className="form-label">
                                                Container Type
                                              </Label>
                                              <Input
                                                type="text"
                                                name={`container-type-${data.id}`}
                                                style={{
                                                  textTransform: "capitalize",
                                                }}
                                                className="form-control"
                                                value={shipmentDetails?.container_type
                                                  ?.map((text) => text.name)
                                                  .join(", ")}
                                                readOnly
                                                disabled
                                              />
                                            </div>
                                          </Col>
                                          <Col lg={6}>
                                            <div className="mb-3">
                                              <Label className="form-label">
                                                Data Sample
                                              </Label>
                                              <Select
                                                value={sampleData.filter(
                                                  (d) => {
                                                    return (
                                                      d.value ===
                                                      data.ship_data_sample_id
                                                    );
                                                  }
                                                )}
                                                name={`data-sample-${data.id}`}
                                                onChange={(e) =>
                                                  handleChangeDataSample(
                                                    e,
                                                    data.id
                                                  )
                                                }
                                                options={sampleData}
                                                classNamePrefix="select2-selection"
                                                placeholder="Select from List"
                                              />
                                            </div>
                                          </Col>
                                          <Col lg={6}>
                                            <div className="mb-3">
                                              <Label
                                                className="form-label"
                                                htmlFor="sample_barcode"
                                              >
                                                Samples Barcode
                                              </Label>
                                              <AvField
                                                name="sample_barcode"
                                                errorMessage="Please enter barcode"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                onChange={(e) =>
                                                  handleChangeInput(e, data.id)
                                                }
                                                type="text"
                                                className="form-control"
                                                id={`sample_barcode-${data.id}`}
                                                value={data.sample_barcode}
                                              />
                                            </div>
                                          </Col>
                                          {/* <Col lg={6}>
                                            <div className="mb-3">
                                              <Label className="form-label">
                                                Test Result
                                              </Label>
                                              <Select
                                                value={optionResult.filter(
                                                  (c) => {
                                                    return (
                                                      c.value ===
                                                      data.test_result
                                                    );
                                                  }
                                                )}
                                                name={`test-result-${data.id}`}
                                                onChange={(e) =>
                                                  handleChangeTestResult(
                                                    e,
                                                    data.id
                                                  )
                                                }
                                                options={optionResult}
                                                classNamePrefix="select2-selection"
                                                placeholder="Select from List"
                                              />
                                            </div>
                                          </Col> */}
                                        </Row>
                                      </Col>

                                      <Col lg="4">
                                        <div className="mb-3">
                                          <Label
                                            className="form-label d-none"
                                            htmlFor={data.id}
                                          >
                                            Upload Pictures
                                          </Label>
                                          <Input
                                            type="file"
                                            name={`upload-image-${data.id}`}
                                            multiple
                                            id={data.id}
                                            style={{ display: "none" }}
                                            onChange={(e) =>
                                              handleFileSelect(e)
                                            }
                                            accept="image/*"
                                          />

                                          <div className="d-flex flex-wrap align-items-center">
                                            {data.ship_container_files?.length >
                                              0 &&
                                              data.ship_container_files?.map(
                                                (file, i) => (
                                                  <div
                                                    key={i}
                                                    className="me-3 position-relative"
                                                    style={{
                                                      width: "100px",
                                                      height: "80px",
                                                    }}
                                                  >
                                                    <img
                                                      className="w-100 h-80 object-fit-cover"
                                                      src={file?.file}
                                                      alt="image_placeholder"
                                                    />
                                                  </div>
                                                )
                                              )}

                                            <div
                                              className="me-3 position-relative d-flex flex-column align-items-center"
                                              style={{
                                                width: "100px",
                                                height: "100px",
                                                cursor: "pointer",
                                              }}
                                              onClick={() => {
                                                const fileInput =
                                                  document.getElementById(
                                                    data.id
                                                  );
                                                if (fileInput) {
                                                  fileInput.click();
                                                }
                                              }}
                                            >
                                              <div
                                                className="w-100 h-100 d-flex flex-column justify-content-center align-items-center border rounded"
                                                style={{
                                                  borderStyle: "dashed",
                                                  borderColor: "gray",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                <i className="ri-upload-2-line font-size-24 text-gray-500"></i>
                                                <span>Upload</span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </AvForm>
                        );
                      })}
                  </div>
                </Col>
              </Row>
            </div>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
}

export default PullShipmentSamples;
