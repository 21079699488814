import React, { useCallback, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import ApiUtils from "../../api/ApiUtils";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setShipmentId } from "../../redux/slices/shipmentSlice";
import { ToasterError, ToasterSuccess } from "../../helper/ToasterHelper";
import swal from "sweetalert";
import { useUserRole } from "../../helper/UserProvider";
import { USER_TYPE } from "../../config/constants";
function ShipmentTable(props) {
  const [shipmentListData, setShipmentListData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [searchValue, setSearchValue] = useState("");
  const shipmentData = useSelector((state) => state?.shipmentSlice);

  const { userRole } = useUserRole();
  const isUserTypeAdmin = userRole.role_id === USER_TYPE.ADMIN;

  const queryParameters = localStorage.getItem("textSearch");
  async function fetchShipmentList() {
    await ApiUtils.getShipmentList(
      `search=${
        queryParameters ? queryParameters : searchValue
      }&sort_column=${sortColumn}&sort_direction=${sortDirection}&page_size=${sizePerPage}&page=${currentPage}`
    )
      .then((res) => {
        if (res.status === 200) {
          setTotalRecords(res ? res.data.data.total : 0);
          setShipmentListData(res?.data?.data?.data);
        }
      })
      .catch((err) => setShipmentListData([]));
  }
  const navigate = useNavigate();
  const disptach = useDispatch();
  useEffect(() => {
    fetchShipmentList();

    setTimeout(() => {
      localStorage.removeItem("textSearch");
    }, 20000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, sizePerPage]);

  useEffect(() => {
    if (shipmentData?.id) {
      disptach(setShipmentId(""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipmentData]);
  const tableData = shipmentListData.map((item) => {
    const staticData = {
      id: item.id,
      shipment_type: item.shipment_type,
      customs_decleration_number: item.customs_decleration_number,
      company_name: item.company_name,
      invoice_no: item.invoice_no,
      invoice_status:
        Number(item.invoice_status) === 1
          ? "Pending"
          : Number(item.invoice_status) === 2
          ? "Paid"
          : "Pending",
      shipment_status: item.status,

      shipment_date: item.ship_date
        ? new Date(item.ship_date)
            .toLocaleDateString("en-GB")
            .replace(/\//g, "-")
        : "",
      less_then_50kilos: item.less_then_50kg,
      ports: item.ports,
      kepa_status: item.kepa_status,
    };
    return staticData;
  });

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 400);
    };
  };

  const searchHandleChange = async (value) => {
    setSearchValue(value);
    await ApiUtils.getShipmentList(
      `search=${value}&sort_column=${sortColumn}&sort_direction=${sortDirection}&page_size=${sizePerPage}&page=${currentPage}`
    )
      .then((res) => {
        if (res.status === 200) {
          setTotalRecords(res ? res.data.data.total : 0);
          setShipmentListData(res?.data?.data?.data);
        }
      })
      .catch((err) => setShipmentListData([]));
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearch = useCallback(debounce(searchHandleChange), []);
  const sortShipmentFunc = async (field, order) => {
    await ApiUtils.getShipmentList(
      `search=${
        queryParameters ? queryParameters : searchValue
      }&sort_column=${field}&sort_direction=${order}&page_size=${sizePerPage}&page=${currentPage}`
    )
      .then((res) => {
        if (res.status === 200) {
          setTotalRecords(res ? res.data.data.total : 0);
          setShipmentListData(res?.data?.data?.data);
        }
      })
      .catch((err) => setShipmentListData([]));
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];
  const onEditShipment = (data) => {
    navigate(`/add-new-shipment?shipment-id=${data?.id}`);
  };

  const onPreviewShipmentPull = (row) => {
    navigate(`/pull-shipment-samples/${row.id}`);
  };

  const onPreviewInvoice = (row) => {
    navigate(`/invoice-preview/${row?.invoice_no}`);
  };

  const onPreviewLabTest = (row) => {
    navigate(`/lab-test-result/${row.id}`);
  };

  const onSendInvoice = (row) => {
    ApiUtils.sendInvoiceToClient({ id: row.invoice_no })
      .then((res) => {
        if (res.status === 200) {
          ToasterSuccess(res.data.message);
        }
      })
      .catch((error) => {
        if (error && error.data && error.data.message) {
          ToasterError(error.data.message);
        } else if (error && error.data) {
          ToasterError(error.data);
        } else if (error) {
          ToasterError(error);
        }
      });
  };

  const onSendKepa = (item) => {
    ApiUtils.initialKepaRequest({ id: item.id })
      .then((res) => {
        if (res.data.InsertChemicalDeclarationQCResult?.Result) {
          ToasterSuccess("Insert Chemical Success");
          fetchShipmentList();
        } else if (res.data.ItemsNotUnderEPAResult?.Result) {
          ToasterSuccess("Items Not Under KEPA Success");
          fetchShipmentList();
        } else if (res.data.InsertChemicalDeclarationQCResult?.Remarks) {
          ToasterError(res.data.InsertChemicalDeclarationQCResult?.Remarks);
        } else if (res.data.ItemsNotUnderEPAResult?.Remarks) {
          ToasterError(res.data.ItemsNotUnderEPAResult?.Remarks);
        }
      })
      .catch((err) => {
        if (err) {
          ToasterError(err);
        } else {
          ToasterError("Internal server Error");
        }
      });
  };

  const onDownloadLabReport = async (row) => {
    await ApiUtils.getLabResult(row.id)
      .then((res) => {
        if (res.status === 200) {
          window.location.href = res.data.data;
        }
      })
      .catch((error) => {
        if (error && error.data && error.data.message) {
          ToasterError(error.data.message);
        } else if (error && error.data) {
          ToasterError(error.data);
        } else if (error) {
          ToasterError(error);
        }
      });
  };

  const onDownloadMsds = async (row) => {
    await ApiUtils.getSmdsFile(row.id)
      .then((res) => {
        if (res.status === 200) {
          window.location.href = res.data.data;
        }
      })
      .catch((error) => {
        if (error && error.data && error.data.message) {
          ToasterError(error.data.message);
        } else if (error && error.data) {
          ToasterError(error.data);
        } else if (error) {
          ToasterError(error);
        }
      });
  };

  const onDeleteShipment = (data) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete Shipment?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        ApiUtils.deleteShipment(data.id)
          .then((res) => {
            if (res.status === 200) {
              swal(res.data.message, {
                icon: "success",
              });
              setCurrentPage(1);
              fetchShipmentList();
              props.updateDashboardCount();
            }
          })
          .catch((error) => {
            if (error && error.data && error.data.message) {
              ToasterError(error.data.message);
            } else if (error && error.data) {
              ToasterError(error.data);
            } else if (error) {
              ToasterError(error);
            }
          });
      } else {
        swal({
          title: "Shipment not deleted!",
          icon: "error",
        });
      }
    });
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortShipmentFunc(field, order);
      },
    },
    {
      dataField: "shipment_type",
      text: "Shipment Type",
    },
    {
      dataField: "customs_decleration_number",
      text: "Customs ID",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortShipmentFunc(field, order);
      },
    },
    {
      dataField: "company_name",
      text: "Company Name",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortShipmentFunc(field, order);
      },
    },

    {
      dataField: "ports",
      text: "Port",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortShipmentFunc(field, order);
      },
    },

    {
      dataField: "invoice_no",
      text: "Invoice No.",
      sort: true,
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortShipmentFunc(field, order);
      },
    },
    {
      dataField: "invoice_status",
      text: "Invoice Status",
      sort: true,
      formatter: (cell, row) => {
        return (
          <div>
            {row?.invoice_status === "Paid" ? (
              <span className="badge bg-success font-size-12">Paid</span>
            ) : (
              <span className="badge bg-warning font-size-12">
                {row?.invoice_status}
              </span>
            )}
          </div>
        );
      },
      onSort: async (field, order) => {
        setSortColumn(field);
        setSortDirection(order);
        sortShipmentFunc(field, order);
      },
    },

    {
      dataField: "kepa_status",
      text: "KEPA Status",
      formatter: (_cellContent, row) => {
        return (
          <div>
            <a
                className={"d-inline-block align-middle text-dark"}
                target="_blank" rel={"noreferrer"}
                href={(`/shipment-log/${row.id}`)}
                style={{marginRight: "10px"}}
            >
              <i className={"ri-file-list-3-line"}></i>
            </a>
            <span className={"d-inline-block align-middle"}>{row.kepa_status ? row.kepa_status : 'N/A'}</span>
          </div>
        );
      }
    },

    {
      dataField: "shipment_status",
      text: "Shipment Status",
    },
    {
      dataField: "shipment_date",
      text: "Shipment Date",
    },

    {
      dataField: "menu",
      isDummyField: true,
      text: "Actions",
      formatter: (_cellContent, row) => (
        <>
          <UncontrolledDropdown className="text-center">
            <DropdownToggle tag="i" className="arrow-none">
              <i className="mdi mdi-dots-vertical"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              {shipmentListData.filter((obj) => row.id === obj.id)[0].step ===
              "5" ? (
                <>
                  <DropdownItem
                    className="action-dropdown-invoice me-1"
                    onClick={() => onPreviewShipmentPull(row)}
                  >
                    <i className="ri-camera-line"></i>
                    Pull Samples
                  </DropdownItem>
                  <DropdownItem
                    className="action-dropdown-invoice me-1"
                    onClick={() => onSendKepa(row)}
                  >
                    <i
                      className="ri-send-plane-fill
"
                    ></i>
                    Send to KEPA
                  </DropdownItem>
                  {isUserTypeAdmin && (
                    <DropdownItem
                      className="action-dropdown-invoice me-1"
                      onClick={() => onPreviewLabTest(row)}
                    >
                      <i className="mdi mdi-test-tube"></i>
                      View Lab Test Result
                    </DropdownItem>
                  )}

                  <DropdownItem
                    className="action-dropdown-invoice me-1"
                    onClick={() => onPreviewInvoice(row)}
                  >
                    <i className="ri ri-eye-fill"></i>
                    View Invoice
                  </DropdownItem>
                  <DropdownItem
                    className="action-dropdown-invoice me-1"
                    onClick={() => onSendInvoice(row)}
                  >
                    <i
                      className="ri-send-plane-fill
"
                    ></i>
                    Send Invoice
                  </DropdownItem>
                </>
              ) : null}

              {shipmentListData.filter((obj) => row.id === obj.id)[0]
                .smds_file === "true" &&
              shipmentListData.filter((obj) => row.id === obj.id)[0].step ===
                "5" ? (
                <DropdownItem
                  className="action-dropdown-invoice me-1"
                  onClick={() => onDownloadMsds(row)}
                >
                  <i className="ri-download-cloud-2-fill "></i>
                  MSDS
                </DropdownItem>
              ) : null}

              {shipmentListData.filter((obj) => row.id === obj.id)[0]
                .lab_report_file === "true" &&
              shipmentListData.filter((obj) => row.id === obj.id)[0].step ===
                "5" &&
              isUserTypeAdmin ? (
                <DropdownItem
                  className="action-dropdown-invoice me-1"
                  onClick={() => onDownloadLabReport(row)}
                >
                  <i className="ri-download-cloud-2-fill"></i>
                  Lab Report
                </DropdownItem>
              ) : null}
              {isUserTypeAdmin && (
                <>
                  <DropdownItem
                    className="action-dropdown-invoice me-1"
                    onClick={() => onEditShipment(row)}
                  >
                    <i className="mdi mdi-pencil "></i>
                    Update
                  </DropdownItem>
                  <DropdownItem
                    className="action-dropdown-invoice me-1"
                    onClick={() => onDeleteShipment(row)}
                  >
                    <i className="ri-delete-bin-2-fill "></i>
                    Delete
                  </DropdownItem>
                </>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        </>
      ),
    },
  ];

  const handleTableChange = (_type, { page, sizePerPage }) => {
    setCurrentPage(page);
    setSizePerPage(sizePerPage);
  };

  return (
    <Row>
      <Col className="col-12">
        <Card>
          <CardBody>
            <p className="card-title-desc"></p>
            <PaginationProvider
              pagination={paginationFactory({
                custom: true,
                sizePerPage: sizePerPage,
                totalSize: Number(totalRecords),
                page: currentPage,
              })}
              keyField="id"
              columns={columns}
              data={tableData}
            >
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="id"
                  columns={columns}
                  data={tableData}
                  search
                >
                  {(toolkitProps) => (
                    <React.Fragment>
                      <Row className="mb-2">
                        <Col md={4}>
                          <div className="search-box me-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <Input
                                type="text"
                                className="form-control"
                                placeholder="Search"
                                onChange={(e) => onSearch(e.target.value)}
                                defaultValue={
                                  queryParameters ? queryParameters : ""
                                }
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12">
                          <div
                            className="table-responsive"
                            style={{ minHeight: "300px" }}
                          >
                            <BootstrapTable
                              remote
                              keyField={"id"}
                              responsive
                              bordered={true}
                              hover={true}
                              pagination={paginationFactory}
                              defaultSorted={defaultSorted}
                              classes={
                                "table align-middle table-nowrap bg-white"
                              }
                              headerWrapperClasses={"thead-light"}
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                              onTableChange={handleTableChange}
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row className="align-items-md-center mt-30">
                        <Col className="inner-custom-pagination d-flex mt-3">
                          <div className="d-inline">
                            <SizePerPageDropdownStandalone
                              {...paginationProps}
                            />
                          </div>
                          <div className="text-md-right ms-auto">
                            <PaginationListStandalone {...paginationProps} />
                          </div>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default ShipmentTable;
