import api from "./Index";
const ApiUtils = {
  getShipmentReport: async function (params) {
    try {
      const response = await api.get(`common/get-shipment-pdf?${params}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getShipmentLogs: async function (params) {
    try {
      const response = await api.get(`/get-shipment-logs?${params}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getCompanyReport: async function () {
    try {
      const response = await api.get("common/get-company-pdf");
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getProfile: async function () {
    try {
      const response = await api.get("common/get-profile");
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getSmdsFile: async function (params) {
    try {
      const response = await api.get(`common/get-smds-file?id=${params}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getLabResult: async function (params) {
    try {
      const response = await api.get(`common/get-lab-result-file?id=${params}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  deleteShipment: async function (params) {
    try {
      const response = await api.delete(`common/delete-shipment?id=${params}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  updateDataComponent: async function (params) {
    try {
      const response = await api.post("common/update-data-component", params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  initialKepaRequest: async function (params) {
    try {
      const response = await api.post("initial-kepa-request", params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getCompanyByID: async function (params) {
    try {
      const response = await api.get(
        `common/client-contact-by-id?id=${params}`
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getShipmentCount: async function (params) {
    try {
      const response = await api.get(`common/shipment-count?${params}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  generatePaymentUrl: async function (params) {
    try {
      const response = await api.get(`common/generate-payment-url?${params}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  createPaymentDetail: async function (params) {
    try {
      const response = await api.post("common/create-payment-detail", params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  sendInvoiceToClient: async function (params) {
    try {
      const response = await api.post("common/send-invoice", params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  updateUserPassword: async function (params) {
    try {
      const response = await api.put("admin/update-user-password", params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  updateInvoiceStatus: async function (params) {
    try {
      const response = await api.put("common/update-invoice-status", params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },

  getInvoices: async function (params) {
    try {
      const response = await api.get(`common/get-invoice?${params}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },

  authLogin: async function (params) {
    try {
      const response = await api.post("admin/login", params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getUserList: async function (params) {
    try {
      const response = await api.get(`admin/user-list?${params}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  createUser: async function (params) {
    try {
      const response = await api.post("admin/create-user", params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  deleteUser: async function (params) {
    try {
      const response = await api.delete(`admin/user-delete?id=${params}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  userUpdate: async function (params) {
    try {
      const response = await api.put("admin/user-update", params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  updateUserStatus: async function (params) {
    try {
      const response = await api.put("admin/update-user-status", params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getContactsList: async function (params) {
    try {
      const response = await api.get(`common/client-contact-list?${params}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getCountriesList: async function () {
    try {
      const response = await api.get("admin/get-countries");
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  createClientContact: async function (params) {
    try {
      const response = await api.post("common/create-client-contact", params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  updateClientContact: async function (params) {
    try {
      const response = await api.put("common/client-contact-update", params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  deleteClientContact: async function (params) {
    try {
      const response = await api.delete(
        `common/client-contact-delete?id=${params}`
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  adminLogout: async function () {
    try {
      const response = await api.get("admin/logout");
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  userLogout: async function () {
    try {
      const response = await api.get("user-logout");
      return response;
    } catch (error) {
      throw error.response;
    }
  },

  addDataSample: async function (params) {
    try {
      const response = await api.post("common/create-data-sample", params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getDataSample: async function (params) {
    try {
      const response = await api.get(`common/data-sample-list?${params}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  deleteDataSample: async function (params) {
    try {
      const response = await api.delete(
        `common/data-sample-delete?id=${params}`
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  editDataSample: async function (params) {
    try {
      const response = await api.post("common/data-sample-update", params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getEmployeeList: async function () {
    try {
      const response = await api.get("common/employee-list");
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  deleteDataSampleListComponent: async function (params) {
    try {
      const response = await api.delete(
        `common/data-component-delete?id=${params}`
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getContainerTypeList: async function () {
    try {
      const response = await api.get("common/container-type-list");
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getBrokerList: async function (params) {
    try {
      const response = await api.get(`common/broker-list?${params}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getPortList: async function () {
    try {
      const response = await api.get("common/port-list");
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  createShipment: async function (params) {
    try {
      const response = await api.post("common/create-shipment", params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  searchDataSample: async function (params) {
    try {
      const response = await api.get(
        `common/search-data-sample?search=${params}`
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getShipmentById: async function (params) {
    try {
      const response = await api.get(`common/get-shipment-by-id?${params}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },

  getBrokersList: async function (params) {
    try {
      const response = await api.get(`common/broker-list?${params}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },

  createBroker: async function (params) {
    try {
      const response = await api.post("common/create-broker", params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  updateBroker: async function (params) {
    try {
      const response = await api.put("common/broker-update", params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  deleteBroker: async function (params) {
    try {
      const response = await api.delete(`common/broker-delete?id=${params}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getShipmentList: async function (params) {
    try {
      const response = await api.get(`common/shipment-list?${params}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getInvoicePreview: async function (params) {
    try {
      const response = await api.get(`common/get-invoice-by-id?id=${params}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  updateShipContainer: async function (params) {
    try {
      const response = await api.post("common/update-ship-container", params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  deleteDataSampleInShipment: async function (params) {
    try {
      const response = await api.delete(
        `common/delete-ship-data-sample?id=${params}`
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getShipmentChart: async function () {
    try {
      const response = await api.get("common/shipment-chart");
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getPaymentChart: async function () {
    try {
      const response = await api.get("common/invoice-chart");
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getShipmentDashboardCount: async function () {
    try {
      const response = await api.get("common/dashboard-count");
      return response;
    } catch (error) {
      throw error.response;
    }
  },
};

export default ApiUtils;
