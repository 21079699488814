import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, TabPane, Label, Input } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import ApiUtils from "../../../api/ApiUtils";
import { ToasterError, ToasterWarning } from "../../../helper/ToasterHelper";
import {
  setContainerNumber,
  setTypeOfShipment,
  setShipmentId,
} from "../../../redux/slices/shipmentSlice";

function Step1({
  tabId,
  clickedNextTab,
  setActiveTabProgress,
  setClickedNextTab,
  setProgressValue,
  progressValue,
  allShipmentDetails,
  loading,
  searchShipmentId,
}) {
  const [shipmentType, setShipmemtType] = useState(
    `${allShipmentDetails?.ship_type ? allShipmentDetails?.ship_type : 1}`
  );
  const [containerTypeList, setContainerTypeList] = useState([]);
  const dispatch = useDispatch();
  const shipmentData = useSelector((state) => state?.shipmentSlice);
  const [containerTypeId, setContainerTypeId] = useState(
    `${
      allShipmentDetails?.content_type_id
        ? allShipmentDetails?.content_type_id
        : 2
    }`
  );

  useEffect(() => {
    if (
      shipmentData?.stepNumber > 4 &&
      !shipmentData?.id &&
      !searchShipmentId
    ) {
      setShipmemtType(1);
      setContainerTypeId(2);
      dispatch(setShipmentId(""));
      dispatch(setContainerNumber(""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipmentData?.stepNumber]);
  useEffect(() => {
    if (Object.keys(allShipmentDetails).length > 0) {
      setShipmemtType(
        allShipmentDetails?.ship_type ? allShipmentDetails?.ship_type : 1
      );
      setContainerTypeId(
        allShipmentDetails?.content_type_id
          ? allShipmentDetails?.content_type_id
          : 2
      );
    }
  }, [allShipmentDetails]);

  async function fetchContainerTypeList() {
    await ApiUtils.getContainerTypeList()
      .then((res) => {
        if (res.status === 200) {
          setContainerTypeList(res.data.data);
        }
      })
      .catch((err) => setContainerTypeList([]));
  }
  useEffect(() => {
    fetchContainerTypeList();
  }, []);

  useEffect(() => {
    if (clickedNextTab) {
      if (shipmentType === "" || containerTypeId === "") {
        ToasterWarning("Please select all the fields");
        setClickedNextTab(false);
      } else {
        goToStepTwo();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickedNextTab]);

  async function goToStepTwo() {
    const formData = new FormData();
    formData.append("assigned_id", 1);
    formData.append("ship_type", shipmentType);
    formData.append("content_type_id", containerTypeId);
    formData.append("step", 1);
    if (shipmentData?.id) {
      formData.append("id", shipmentData?.id);
    }
    await ApiUtils.createShipment(formData)
      .then((res) => {
        if (res.status === 200) {
          setActiveTabProgress(tabId + 1);
          setProgressValue(progressValue + 25);
          setClickedNextTab(false);
          dispatch(setShipmentId(res?.data?.data?.shipment?.id));
          dispatch(setTypeOfShipment(res?.data?.data?.shipment.ship_type));
        }
      })
      .catch((error) => {
        if (error && error.data && error.data.message) {
          ToasterError(error.data.message);
        } else if (error && error.data) {
          ToasterError(error.data);
        } else if (error) {
          ToasterError(error);
        }
      });
  }

  const handleShipmentType = (e) => {
    if (e?.target?.defaultValue === "import") {
      setShipmemtType(1);
    } else if (e?.target?.defaultValue === "export") {
      setShipmemtType(2);
    }
  };
  const handleContainerType = (e) => {
    setContainerTypeId(e.target.id);
  };
  return (
    <React.Fragment>
      {!loading ? (
        <TabPane tabId={tabId}>
          <Row className="mt-5">
            <Col lg="4" className="bright">
              <Row>
                <Col>
                  <Card className="import-card">
                    <CardBody>
                      <Label className="card-radio-label add-shipment-label-first-tab-import">
                        <Input
                          className="form-check-input"
                          type="radio"
                          name="shipmentTypeRadios"
                          id="shipment-type-import"
                          value="import"
                          checked={Number(shipmentType) === 1 ? true : false}
                          onChange={handleShipmentType}
                        />
                        <div className="d-flex flex-column align-items-center">
                          <i className="fas fa-plane-arrival fa-2x mb-1"></i>
                          <span className="card-text">Import</span>
                        </div>
                      </Label>
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <Card className="export-card">
                    <CardBody>
                      <Label className="card-radio-label add-shipment-label-first-tab-import">
                        <Input
                          className="form-check-input"
                          type="radio"
                          name="shipmentTypeRadios"
                          id="shipment-type-export"
                          value="export"
                          onChange={handleShipmentType}
                          checked={Number(shipmentType) === 2 ? true : false}
                        />
                        <div className="d-flex flex-column align-items-center">
                          <i className="fas fa-plane-departure fa-2x mb-1"></i>
                          <span className="card-text">Export</span>
                        </div>
                      </Label>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                {containerTypeList.length > 0 &&
                  containerTypeList.map((value, i) => {
                    return (
                      <Col lg="4" key={i}>
                        <Card className="shipment">
                          <CardBody className="card-body-step-1">
                            <Label className="card-radio-label add-shipment-label-first-tab">
                              <Input
                                className="form-check-input"
                                type="radio"
                                value={value.name}
                                id={value.id}
                                name="containerType"
                                onChange={handleContainerType}
                                checked={
                                  Number(value.id) === Number(containerTypeId)
                                }
                              />
                              <div className="d-flex flex-column align-items-center">
                                <span
                                  className="card-text larger-text"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {value.name}
                                </span>
                              </div>
                            </Label>
                          </CardBody>
                        </Card>
                      </Col>
                    );
                  })}
              </Row>
            </Col>
          </Row>
        </TabPane>
      ) : (
        ""
      )}
    </React.Fragment>
  );
}

export default Step1;
