import React, { useEffect, useState } from "react";
import { Row, Col, TabPane, Label } from "reactstrap";
import Select from "react-select";
import { AvField, AvForm } from "availity-reactstrap-validation";
import ApiUtils from "../../../api/ApiUtils";
import { ToasterError } from "../../../helper/ToasterHelper";
import { useDispatch, useSelector } from "react-redux";
import { setContainerNumber } from "../../../redux/slices/shipmentSlice";
function Step2({
  tabId,
  clickedNextTab,
  setActiveTabProgress,
  setClickedNextTab,
  setProgressValue,
  progressValue,
  allShipmentDetails,
  searchShipmentId,
}) {
  const [contactList, setContactList] = useState([]);
  const [portList, setPortList] = useState([]);
  const [brokerList, setBrokerList] = useState([]);
  const [countriesOptions, setCountriesOptions] = useState([]);
  const [fieldsData, setFieldsData] = useState({
    ship_date: allShipmentDetails?.ship_date
      ? allShipmentDetails?.ship_date
      : "",
    company_name_id: allShipmentDetails?.company_name_id
      ? allShipmentDetails?.company_name_id
      : "",
    broker_id: allShipmentDetails?.broker_id
      ? allShipmentDetails?.broker_id
      : "",
    port_id: allShipmentDetails?.port_id ? allShipmentDetails?.port_id : "",
    customs_decleration_number: allShipmentDetails?.customs_decleration_number
      ? allShipmentDetails?.customs_decleration_number
      : "",
    awb_number: allShipmentDetails?.awb_number
      ? allShipmentDetails?.awb_number
      : "",
    total_container: allShipmentDetails?.total_container
      ? parseInt(allShipmentDetails?.total_container)
      : "",
    exporting_import_country_id: allShipmentDetails?.exporting_import_country_id
      ? allShipmentDetails?.exporting_import_country_id
      : "",
    manufacturing_company_name: allShipmentDetails?.manufacturing_company_name
      ? allShipmentDetails?.manufacturing_company_name
      : "",
    chemical_container: allShipmentDetails?.chemical_container
      ? parseInt(allShipmentDetails?.chemical_container)
      : "",
    origin_country_id: allShipmentDetails?.origin_country_id
      ? allShipmentDetails?.origin_country_id
      : "",
    origin_certificate_number: allShipmentDetails?.origin_certificate_number
      ? allShipmentDetails?.origin_certificate_number
      : "",
    physical_container: allShipmentDetails?.physical_container
      ? parseInt(allShipmentDetails?.physical_container)
      : "",
    invoice_date: allShipmentDetails?.invoice_date
      ? allShipmentDetails?.invoice_date
      : "",
    invoice_number: allShipmentDetails?.invoice_number
      ? allShipmentDetails?.invoice_number
      : "",
  });

  const shipmentData = useSelector((state) => state?.shipmentSlice);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchContactList();
    fetchPortList();
    fetchBrokerList();
    fetchCountriesList();
    // if (shipmentData?.stepNumber >= 4 && !searchShipmentId) {
    //   setFieldsData({
    //     ship_date: "",
    //     company_name_id: "",
    //     broker_id: "",
    //     port_id: "",
    //     customs_decleration_number: "",
    //     awb_number: "",
    //     total_container: "",
    //     exporting_import_country_id: "",
    //     manufacturing_company_name: "",
    //     chemical_container: "",
    //     origin_country_id: "",
    //     origin_certificate_number: "",
    //     physical_container: "",
    //   });
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  async function fetchContactList() {
    await ApiUtils.getContactsList("type=shipment")
      .then((res) => {
        if (res.status === 200) {
          let contactData = res.data.data.map((val, id) => ({
            ...val,
            value: val.company_name,
            label:
              allShipmentDetails?.content_type_id === 5
                ? `${val.company_name} (${val.shipment_count})`
                : val.company_name,
          }));

          setContactList(contactData);
        }
      })
      .catch((err) => setContactList([]));
  }
  async function fetchPortList() {
    await ApiUtils.getPortList()
      .then((res) => {
        if (res.status === 200) {
          let portData = res.data.data.map((val) => ({
            ...val,
            value: val.id,
            label: val.name,
          }));
          setPortList(portData);
        }
      })
      .catch((err) => setPortList([]));
  }
  async function fetchBrokerList() {
    await ApiUtils.getBrokerList("type=shipment")
      .then((res) => {
        if (res.status === 200) {
          let brokerData = res.data.data.map((val) => ({
            ...val,
            value: val.id,
            label: val.name,
          }));
          setBrokerList(brokerData);
        }
      })
      .catch((err) => setBrokerList([]));
  }
  const fetchCountriesList = async () => {
    await ApiUtils.getCountriesList()
      .then((res) => {
        if (res.status === 200) {
          let countryData = res.data.data.map((c) => ({
            ...c,
            value: c.id,
            label: c.name,
          }));
          setCountriesOptions(countryData);
        }
      })
      .catch((err) => setCountriesOptions([]));
  };

  const handleChangeFields = (e) => {
    if (e?.label) {
      setFieldsData({
        ...fieldsData,
        [e.label]: e.id,
      });
    } else {
      setFieldsData({
        ...fieldsData,
        [e.target.name]: e.target.value,
      });
    }
  };
  const contactHandleChange = (e) => {
    setFieldsData({
      ...fieldsData,
      company_name_id: e.id,
    });
  };
  const brokerHandleChange = (e) => {
    setFieldsData({
      ...fieldsData,
      broker_id: e.id,
    });
  };
  const portHandleChange = (e) => {
    setFieldsData({
      ...fieldsData,
      port_id: e.id,
    });
  };

  const exportCountryHandleChange = (e) => {
    setFieldsData({
      ...fieldsData,
      exporting_import_country_id: e.id,
    });
  };
  const originCountryHandleChange = (e) => {
    setFieldsData({
      ...fieldsData,
      origin_country_id: e.id,
    });
  };

  useEffect(() => {
    if (clickedNextTab) {
      goToStepThree();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickedNextTab]);

  async function goToStepThree(e, error, values) {
    if (
      error?.length > 0 ||
      fieldsData.broker_id === "" ||
      fieldsData.company_name_id === "" ||
      fieldsData.port_id === "" ||
      fieldsData.origin_country_id === "" ||
      fieldsData.exporting_import_country_id === ""
    ) {
      // ToasterWarning("Please fill all the input field");
    } else if (
      parseInt(fieldsData.total_container) <
      parseInt(fieldsData.chemical_container) +
        parseInt(fieldsData.physical_container)
    ) {
      ToasterError(
        "Sum of Physical & Chemical Container should not be greater than Total Container"
      );
    } else if (error !== undefined) {
      e.preventDefault();
      const formData = new FormData();
      formData.append("awb_number", fieldsData.awb_number);
      formData.append("broker_id", fieldsData.broker_id);
      formData.append(
        "chemical_container",
        fieldsData.chemical_container ? fieldsData.chemical_container : 0
      );
      formData.append("company_name_id", fieldsData.company_name_id);
      formData.append(
        "customs_decleration_number",
        fieldsData.customs_decleration_number
      );
      formData.append(
        "exporting_import_country_id",
        fieldsData.exporting_import_country_id
      );
      formData.append(
        "manufacturing_company_name",
        fieldsData.manufacturing_company_name
      );
      formData.append(
        "origin_certificate_number",
        fieldsData.origin_certificate_number
      );
      formData.append("origin_country_id", fieldsData.origin_country_id);
      formData.append(
        "physical_container",
        fieldsData.physical_container ? fieldsData.physical_container : 0
      );
      formData.append("port_id", fieldsData.port_id);
      formData.append("ship_date", fieldsData.ship_date);
      formData.append("invoice_date", fieldsData.invoice_date);
      formData.append("invoice_number", fieldsData.invoice_number);
      formData.append("total_container", fieldsData.total_container);
      formData.append("step", 2);
      formData.append(
        "status",
        allShipmentDetails.status === null
          ? "Pending"
          : allShipmentDetails.status
      );
      formData.append("id", shipmentData?.id);

      await ApiUtils.createShipment(formData)
        .then((res) => {
          if (res.status === 200) {
            setActiveTabProgress(tabId + 1);
            setProgressValue(progressValue + 25);
            setClickedNextTab(false);
            dispatch(
              setContainerNumber(res.data.data.shipment.total_container)
            );
          }
        })
        .catch((error) => {
          if (error && error.data && error.data.message) {
            ToasterError(error.data.message);
          } else if (error && error.data) {
            ToasterError(error.data);
          } else if (error) {
            ToasterError(error);
          }
        });
    }
  }

  return (
    <React.Fragment>
      <TabPane tabId={tabId}>
        <AvForm
          id="step-2"
          onSubmit={(e, error, values) =>
            clickedNextTab && goToStepThree(e, error, values)
          }
        >
          <Row className="mb-3">
            <Col lg="3">
              <div className="mb-3">
                <Label htmlFor="shipment-date-input" className="form-label">
                  Shipment Date
                </Label>
                <AvField
                  errorMessage="Please select date"
                  validate={{ required: { value: true } }}
                  type="date"
                  id="shipment-date-input"
                  name="ship_date"
                  onChange={handleChangeFields}
                  value={fieldsData?.ship_date}
                />
              </div>
            </Col>
            <Col lg="3">
              <div className="mb-3">
                <Label htmlFor="shipment-contact-input" className="form-label">
                  Brokers
                </Label>
                <Select
                  onChange={brokerHandleChange}
                  options={brokerList}
                  classNamePrefix="select2-selection"
                  placeholder="Select from list"
                  defaultValue={
                    shipmentData?.stepNumber <= 4 &&
                    allShipmentDetails?.broker?.map((text, i) => {
                      return { label: text.name, value: text.id };
                    })
                  }
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor:
                        clickedNextTab && fieldsData.broker_id === ""
                          ? "red"
                          : "hsl(0, 0%, 80%)",
                    }),
                  }}
                  validate={{ required: { value: true } }}
                  name="select"
                  required
                />
                {clickedNextTab && fieldsData.broker_id === "" && (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    Please select broker name
                  </div>
                )}
              </div>
            </Col>
            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label" htmlFor="company_name">
                  Company Name
                </Label>
                <Select
                  // value={selectedGroup}
                  onChange={contactHandleChange}
                  options={contactList}
                  classNamePrefix="select2-selection"
                  placeholder="Select from list"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor:
                        clickedNextTab && fieldsData.company_name_id === ""
                          ? "red"
                          : "hsl(0, 0%, 80%)",
                    }),
                  }}
                  defaultValue={
                    shipmentData?.stepNumber <= 4 &&
                    allShipmentDetails?.client_contact?.length > 0
                      ? allShipmentDetails?.client_contact?.map((text, i) => {
                          return { label: text.company_name, value: text.id };
                        })
                      : ""
                  }
                />
                {clickedNextTab && fieldsData.company_name_id === "" && (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    Please select company name
                  </div>
                )}
              </div>
            </Col>
            <Col lg="3">
              <div className="mb-3">
                <Label htmlFor="port_id" className="form-label">
                  Port Name
                </Label>
                <Select
                  // value={selectedGroup}
                  onChange={portHandleChange}
                  options={portList}
                  classNamePrefix="select2-selection"
                  placeholder="Select from list"
                  name="port_id"
                  id="port_id"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor:
                        clickedNextTab && fieldsData.port_id === ""
                          ? "red"
                          : "hsl(0, 0%, 80%)",
                    }),
                  }}
                  defaultValue={
                    shipmentData?.stepNumber <= 4 &&
                    allShipmentDetails?.port?.length > 0
                      ? allShipmentDetails?.port?.map((text, i) => {
                          return { label: text.name, value: text.id };
                        })
                      : ""
                  }
                />
                {clickedNextTab && fieldsData.port_id === "" && (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    Please select port name
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label" htmlFor="customs-declaration-no">
                  Customs Declaration No.
                </Label>
                <AvField
                  errorMessage="Please enter customs declaration no."
                  validate={{ required: { value: true } }}
                  type="text"
                  className="form-control"
                  id="customs-declaration-no"
                  name="customs_decleration_number"
                  onChange={handleChangeFields}
                  value={fieldsData?.customs_decleration_number}
                />
              </div>
            </Col>

            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label" htmlFor="awb-number">
                  AWB Number
                </Label>
                <AvField
                  errorMessage="Please enter AWB number"
                  validate={{ required: { value: true } }}
                  type="text"
                  className="form-control"
                  id="awb-number"
                  name="awb_number"
                  onChange={handleChangeFields}
                  value={fieldsData?.awb_number}
                />
              </div>
            </Col>

            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label" htmlFor="number-of-containers">
                  Number of Containers
                </Label>
                <AvField
                  errorMessage="Please enter number of containers"
                  validate={{ required: { value: true } }}
                  type="number"
                  className="form-control"
                  placeholder="eg: 15"
                  id="number-of-containers"
                  name="total_container"
                  min="0"
                  onKeyPress={(event) => {
                    if (event.charCode < 48) {
                      event.preventDefault();
                    }
                  }}
                  onChange={handleChangeFields}
                  value={fieldsData?.total_container}
                />
              </div>
            </Col>
            <Col lg="3">
              <div className="mb-3">
                <Label htmlFor="invoice-date-input" className="form-label">
                  Invoice Date
                </Label>
                <AvField
                  errorMessage="Please select date"
                  validate={{ required: { value: true } }}
                  type="date"
                  id="invoice-date-input"
                  name="invoice_date"
                  onChange={handleChangeFields}
                  value={fieldsData?.invoice_date}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="3">
              <div className="mb-3">
                <Label
                  className="form-label"
                  htmlFor="exporting_import_country_id"
                >
                  {shipmentData?.shipmentType === 1 ? "Import" : "Export"}{" "}
                  Country
                </Label>
                <Select
                  // value={selectedGroup}
                  onChange={exportCountryHandleChange}
                  options={countriesOptions}
                  classNamePrefix="select2-selection"
                  placeholder="Select from List"
                  name="exporting_import_country_id"
                  id="exporting_import_country_id"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor:
                        clickedNextTab &&
                        fieldsData.exporting_import_country_id === ""
                          ? "red"
                          : "hsl(0, 0%, 80%)",
                    }),
                  }}
                  defaultValue={
                    shipmentData?.stepNumber <= 4 &&
                    allShipmentDetails?.importing_exporting_country?.length > 0
                      ? allShipmentDetails?.importing_exporting_country?.map(
                          (text, i) => {
                            return { label: text.name, value: text.id };
                          }
                        )
                      : ""
                  }
                />
                {clickedNextTab &&
                  fieldsData.exporting_import_country_id === "" && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      Please select any country
                    </div>
                  )}
              </div>
            </Col>
            <Col lg="3">
              <div className="mb-3">
                <Label
                  className="form-label"
                  htmlFor="manufacturing_company_name"
                >
                  Manufacturing Company Name
                </Label>
                <AvField
                  errorMessage="Please enter manufacturing company name"
                  validate={{ required: { value: true } }}
                  type="text"
                  className="form-control"
                  id="manufacturing_company_name"
                  name="manufacturing_company_name"
                  onChange={handleChangeFields}
                  value={fieldsData?.manufacturing_company_name}
                />
              </div>
            </Col>

            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label" htmlFor="chemical-containers">
                  Chemical Containers
                </Label>
                <AvField
                  errorMessage="Please enter chemical containers"
                  validate={{ required: { value: true } }}
                  type="number"
                  className="form-control"
                  placeholder="eg: 10"
                  id="chemical-containers"
                  min="0"
                  onKeyPress={(event) => {
                    if (event.charCode < 48) {
                      event.preventDefault();
                    }
                  }}
                  name="chemical_container"
                  onChange={handleChangeFields}
                  value={fieldsData?.chemical_container || "0"}
                />
              </div>
            </Col>
            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label" htmlFor="awb-number">
                  Invoice Number
                </Label>
                <AvField
                  errorMessage="Please enter invoice number"
                  validate={{ required: { value: true } }}
                  type="text"
                  className="form-control"
                  id="invoice-number"
                  name="invoice_number"
                  onChange={handleChangeFields}
                  value={fieldsData?.invoice_number}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label" htmlFor="origin_country_id">
                  Origin Country
                </Label>
                <Select
                  // value={selectedGroup}
                  onChange={originCountryHandleChange}
                  options={countriesOptions}
                  classNamePrefix="select2-selection"
                  placeholder="Select from list"
                  name="origin_country_id"
                  id="origin_country_id"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor:
                        clickedNextTab && fieldsData.origin_country_id === ""
                          ? "red"
                          : "hsl(0, 0%, 80%)",
                    }),
                  }}
                  defaultValue={
                    shipmentData?.stepNumber <= 4 &&
                    allShipmentDetails?.origin_country?.length > 0
                      ? allShipmentDetails?.origin_country?.map((text, i) => {
                          return { label: text.name, value: text.id };
                        })
                      : ""
                  }
                />
                {clickedNextTab && fieldsData.origin_country_id === "" && (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    Please select origin country
                  </div>
                )}
              </div>
            </Col>

            <Col lg="3">
              <div className="mb-3">
                <Label
                  className="form-label"
                  htmlFor="origin_certificate_number"
                >
                  Origin Certificate No.
                </Label>
                <AvField
                  errorMessage="Please enter origin certificate no."
                  validate={{ required: { value: true } }}
                  type="text"
                  className="form-control"
                  id="origin_certificate_number"
                  name="origin_certificate_number"
                  onChange={handleChangeFields}
                  value={fieldsData?.origin_certificate_number}
                />
              </div>
            </Col>
            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label" htmlFor="physical-containers">
                  Physical Containers
                </Label>
                <AvField
                  errorMessage="Please enter physical containers"
                  validate={{ required: { value: true } }}
                  type="number"
                  className="form-control"
                  placeholder="eg: 10"
                  id="physical-containers"
                  name="physical_container"
                  min="0"
                  onKeyPress={(event) => {
                    if (event.charCode < 48) {
                      event.preventDefault();
                    }
                  }}
                  onChange={handleChangeFields}
                  value={fieldsData?.physical_container || "0"}
                />
              </div>
            </Col>
          </Row>
        </AvForm>
      </TabPane>
    </React.Fragment>
  );
}

export default Step2;
