import React from "react";
import {
  Row,
  Col,
  Card,
  TabPane,
  CardTitle,
  CardText,
  Table,
} from "reactstrap";
import { convertAmountToWords } from "../../../helper/NumberToWords";
function Step4({ tabId, allShipmentDetails }) {
  const invoiceTotalAmountInWords = convertAmountToWords(
    allShipmentDetails?.tota_amount
  );
  return (
    <React.Fragment>
      <TabPane tabId={4}>
        <div className="row justify-content-center">
          <Row>
            <Col lg={4}>
              <Card body className="card-body-new-shipment">
                <CardTitle className="h4 card-title-new-shipment">
                  Shipment Date
                </CardTitle>
                <CardText className="card-text-new-shipment">
                  {allShipmentDetails?.ship_date}
                </CardText>
              </Card>
            </Col>

            <Col lg={4}>
              <Card body className="card-body-new-shipment">
                <CardTitle className="h4 card-title-new-shipment">
                  Customs Declaration No.
                </CardTitle>
                <CardText className="card-text-new-shipment">
                  {allShipmentDetails?.customs_decleration_number}
                </CardText>
              </Card>
            </Col>
            <Col lg={4}>
              <Card body className="card-body-new-shipment">
                <CardTitle className="h4 card-title-new-shipment">
                  Shipment Status
                </CardTitle>
                <CardText className="card-text-new-shipment">
                  {allShipmentDetails?.status}
                </CardText>
              </Card>
            </Col>
            <Col lg={4}>
              <Card body className="card-body-new-shipment">
                <CardTitle className="h4 card-title-new-shipment">
                  Shipment Type
                </CardTitle>
                <CardText className="card-text-new-shipment">
                  {allShipmentDetails?.ship_type === 1
                    ? "Import"
                    : allShipmentDetails?.ship_type === 2
                    ? "Export"
                    : ""}
                </CardText>
              </Card>
            </Col>

            <Col lg={4}>
              <Card body className="card-body-new-shipment">
                <CardTitle className="h4 card-title-new-shipment">
                  Content Type
                </CardTitle>
                <CardText className="card-text-new-shipment">
                  {allShipmentDetails?.container_type?.map((text, i) => {
                    return (
                      <span key={i} style={{ textTransform: "capitalize" }}>
                        {text?.name}
                      </span>
                    );
                  })}
                </CardText>
              </Card>
            </Col>
            <Col lg={4}>
              <Card body className="card-body-new-shipment">
                <CardTitle className="h4 card-title-new-shipment">
                  AWB Number
                </CardTitle>
                <CardText className="card-text-new-shipment">
                  {allShipmentDetails?.awb_number}
                </CardText>
              </Card>
            </Col>
            <Col lg={6}>
              <Card body className="card-body-new-shipment">
                <CardTitle className="h4 card-title-new-shipment">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>
                      {allShipmentDetails?.ship_type === 1
                        ? "Import"
                        : "Export"}{" "}
                      Company
                    </span>
                    <span>Contact Number</span>
                  </div>
                </CardTitle>
                <CardText className="card-text-new-shipment">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    {allShipmentDetails?.client_contact?.map((text, i) => {
                      return (
                        <React.Fragment key={i}>
                          <span>{text?.company_name}</span>
                          <span>{text?.mobile_number}</span>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </CardText>
              </Card>
            </Col>

            <Col lg={6}>
              <Card body className="card-body-new-shipment">
                <CardTitle className="h4 card-title-new-shipment">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>Customs Broker</span>
                    <span>Contact Number</span>
                  </div>
                </CardTitle>
                <CardText className="card-text-new-shipment">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    {allShipmentDetails?.broker?.map((text, i) => {
                      return (
                        <React.Fragment key={i}>
                          <span>{text?.name}</span>
                          <span>{text?.mobile_number}</span>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </CardText>
              </Card>
            </Col>

            <Col lg={4}>
              <Card body className="card-body-new-shipment">
                <CardTitle className="h4 card-title-new-shipment">
                  Number of Containers
                </CardTitle>
                <CardText className="card-text-new-shipment">
                  {!allShipmentDetails.total_container
                    ? 0
                    : allShipmentDetails?.total_container}
                </CardText>
              </Card>
            </Col>
            <Col lg={4}>
              <Card body className="card-body-new-shipment">
                <CardTitle className="h4 card-title-new-shipment">
                  Chemical Containers
                </CardTitle>
                <CardText className="card-text-new-shipment">
                  {!allShipmentDetails.chemical_container
                    ? 0
                    : allShipmentDetails?.chemical_container}
                </CardText>
              </Card>
            </Col>
            <Col lg={4}>
              <Card body className="card-body-new-shipment">
                <CardTitle className="h4 card-title-new-shipment">
                  Physical Containers
                </CardTitle>
                <CardText className="card-text-new-shipment">
                  {!allShipmentDetails.physical_container
                    ? 0
                    : allShipmentDetails?.physical_container}
                </CardText>
              </Card>
            </Col>
            <Col lg={4}>
              <Card body className="card-body-new-shipment">
                <CardTitle className="h4 card-title-new-shipment">
                  Port Name
                </CardTitle>
                <CardText className="card-text-new-shipment">
                  {allShipmentDetails?.port?.map((text, i) => {
                    return <span key={i}>{text?.name}</span>;
                  })}
                </CardText>
              </Card>
            </Col>

            <Col lg={4}>
              <Card body className="card-body-new-shipment">
                <CardTitle className="h4 card-title-new-shipment">
                  {allShipmentDetails?.ship_type === 1 ? "Import" : "Export"}{" "}
                  Country
                </CardTitle>
                <CardText className="card-text-new-shipment">
                  {allShipmentDetails?.importing_exporting_country?.map(
                    (text, i) => {
                      return <span key={i}>{text.name}</span>;
                    }
                  )}
                </CardText>
              </Card>
            </Col>
            <Col lg={4}>
              <Card body className="card-body-new-shipment">
                <CardTitle className="h4 card-title-new-shipment">
                  Manufacturing Company Name
                </CardTitle>
                <CardText className="card-text-new-shipment">
                  {allShipmentDetails?.manufacturing_company_name}
                </CardText>
              </Card>
            </Col>
            <Col lg={4}>
              <Card body className="card-body-new-shipment">
                <CardTitle className="h4 card-title-new-shipment">
                  Origin Country{" "}
                </CardTitle>
                <CardText className="card-text-new-shipment">
                  {allShipmentDetails?.origin_country?.map((text, i) => {
                    return <span key={i}>{text?.name}</span>;
                  })}
                </CardText>
              </Card>
            </Col>
            <Col lg={4}>
              <Card body className="card-body-new-shipment">
                <CardTitle className="h4 card-title-new-shipment">
                  Origin Certificate No.
                </CardTitle>
                <CardText className="card-text-new-shipment">
                  {allShipmentDetails?.origin_certificate_number}
                </CardText>
              </Card>
            </Col>
            <Col lg={4}>
              <Card body className="card-body-new-shipment">
                <CardTitle className="h4 card-title-new-shipment">
                  Shipment Invoice #
                </CardTitle>
                <CardText className="card-text-new-shipment">
                  {allShipmentDetails?.invoice_number}
                </CardText>
              </Card>
            </Col>
            <Col lg={4}>
              <Card body className="card-body-new-shipment">
                <CardTitle className="h4 card-title-new-shipment bg-primary text-white">
                  Invoice No.
                </CardTitle>
                <CardText className="card-text-new-shipment">
                  {allShipmentDetails?.invoice?.length > 0
                    ? allShipmentDetails?.invoice?.map((text, i) => {
                        return <span key={i}>{text?.id}</span>;
                      })
                    : "---"}
                </CardText>
              </Card>
            </Col>
            <Col lg={4}>
              <Card body className="card-body-new-shipment">
                <CardTitle className="h4 card-title-new-shipment bg-primary text-white">
                  Invoice Total
                </CardTitle>
                <CardText className="card-text-new-shipment">
                  {allShipmentDetails?.tota_amount}
                </CardText>
              </Card>
            </Col>
            <Col lg={4}>
              <Card body className="card-body-new-shipment">
                <CardTitle className="h4 card-title-new-shipment bg-primary text-white">
                  Total Amount in Words
                </CardTitle>
                <CardText
                  className="card-text-new-shipment"
                  style={{ textTransform: "capitalize" }}
                >
                  {invoiceTotalAmountInWords} Kuwaiti Dinar
                </CardText>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Card>
                <>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table id="tech-companies-1" striped bordered responsive>
                        <thead>
                          <tr>
                            <th data-priority="1">Container Number</th>
                            <th data-priority="2">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {allShipmentDetails?.ship_container?.map(
                            (data, id) => {
                              return (
                                <tr key={id}>
                                  <td>{data?.number}</td>
                                  <td>Pending</td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </>
              </Card>
            </Col>
            <Col xs={12}>
              <Card>
                <>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table id="tech-companies-1" striped bordered responsive>
                        <thead>
                          <tr>
                            <th data-priority="1">Data Sample</th>
                            <th data-priority="1">MSDS</th>
                            <th data-priority="2">HS Code</th>
                            <th data-priority="3">Weight</th>
                            <th data-priority="3">Unit</th>
                          </tr>
                        </thead>
                        <tbody>
                          {allShipmentDetails?.ship_data_sample?.map(
                            (data, i) => {
                              return (
                                <tr key={i}>
                                  {data?.data_sample?.map((child) => {
                                    return (
                                      <>
                                        <td>{child?.name}</td>
                                        <td>
                                          {child?.smds ? (
                                            <a
                                              href={child?.smds}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              <i className="ri-download-2-fill font-size-20 download-btn-inner" />
                                            </a>
                                          ) : (
                                            ""
                                          )}
                                        </td>
                                        <td>{child?.hsa_code}</td>
                                        <td>{data?.weight}</td>
                                        <td>{child?.unit}</td>
                                      </>
                                    );
                                  })}
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </>
              </Card>
            </Col>
          </Row>
        </div>
      </TabPane>
    </React.Fragment>
  );
}

export default Step4;
